import React from "react";
import styled from "styled-components";

import { Input } from "antd";

const KitInput = (props) => {
    return (
        <StyledInput {...props} />
    );
};


export default KitInput;

const StyledInput = styled(Input)`
    width: 100%;
    height: 50px;
    background: #F4F4F4 !important;
    border-radius: 10px;
    border: none;
    padding: 15px;
    
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    
    &:hover,
    &:focus {
        border-color: #333333 !important;
        box-shadow: 0 0 0 1px rgb(0 0 0) !important;
        border-right-width: 1px !important;
        outline: 0;
    }
`;
