import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from 'react-router-dom';

import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "../../assets/icons/narrow.svg";
import KitButton from "../../ui-kit/Button";
import { Form, Spin } from "antd";
import authService from "../../services/auth.service";
import isEmail from "validator/lib/isEmail";
import Input from "../../ui-kit/Input";

const ForgotPassword = () => {
    const [username, setUsername] = useState("");
    const [password] = useState("");
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const { isLoggedIn } = useSelector(state => state.auth);


    const onChangeEmail = (e) => {
        const username = e.target.value;
        setUsername(username);

        if (error) {
            setError('');
        }

        if (!username || !isEmail(username)) {
            setEmailError('Не верный email');
        } else {
            setEmailError('');
        }
    };


    const handleSubmit = (e) => {
        setLoading(true);

        authService.forgotPassword(username, password)
            .then((e) => {
                setLoading(false);
                setIsSuccess(true);
            })
            .catch((e) => {
                setError(e);
                setLoading(false);
                setIsSuccess(false);
            });
    };

    if (isLoggedIn) {
        return <Redirect to="/profile" />;
    }


    return (
        <LoginContainer isCenter={isSuccess} onFinish={handleSubmit} >
            <Title>
                {!isSuccess ? 'Восстановление пароля' : 'Инструкция по смене пароля выслана на почту'}
            </Title>
            {!isSuccess ?
                <>
                    <EmailInput
                        type="email"
                        placeholder="E-mail"
                        name="username"
                        value={username}
                        isError={emailError || error === 'Такого аккаунта нет.'}
                        onChange={onChangeEmail}
                    />

                    <StyledButton disabled={loading} htmlType="submit" color="black">
                        Дальше
                        {loading ? <Spin style={{ "margin-top": "5px" }} /> : <ArrowIcon />}
                    </StyledButton>

                    <Message>
                        {error ? <Error>{error}</Error> : 'Уже есть аккаунт?'} <StyledLink to="/auth/login">Войти</StyledLink>
                    </Message>
                </>
                :
                <StyledLink to="/auth/login">
                    <StyledButton color="black">
                        Войти
                    </StyledButton>
                </StyledLink>
            }
        </LoginContainer>
    );
};

export default ForgotPassword;


const LoginContainer = styled(Form)`
  width: 400px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => props.isCenter ? 'center' : 'auto'}

`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;

  margin-top: 37px;
  margin-bottom: 35px;
`;

const EmailInput = styled(Input)`
    background: ${props => props.isHighlighted ? '#F4F4F4' : '#F4F4F4'};
    border-radius: 10px;
    border: ${props => props.isError ? '1px solid red !important' : '1px solid #f1f1f1 !important'};

    width: 340px;
    height: 55px;
    padding-left: 20px;
    margin-bottom: 10px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
      font-weight: 300;
      color: #000000;
    }

    &:focus {
      outline-color: ${props => props.isError ? 'red' : '#000000;'};
      outline: 1;
    }
`;

const StyledButton = styled(KitButton)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 12px;
    width: 340px;

    span {
        font-weight: 600;
        line-height: 21px;
        font-size: 18px !important;
        padding-left: 5px;
    }

    svg {
        color: white !important;
        width: 30px;
        height: 30px;
    }
`;

const Message = styled.div`
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
    text-align: center;

`;

const StyledLink = styled(Link)`
  color: #3D61FF;
`;

const Error = styled.span`
 font-weight: 300;
    font-size: 16px;
    line-height: 30px;

    text-align: center;
      color: red;
    `;
