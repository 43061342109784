import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import templatesService from "../../services/templates.service";
import fileService from "../../services/file.service";
import { history } from "../../helpers/history";
import { encryptId } from "../../helpers/IdEncrypt";
import notificationService from "../../services/notification.service";

export const templateSlice = createSlice({
    name: 'template',
    initialState: {
        list: [],
        current: {
            name: 'Оффер1',
            file: undefined,
            type: 'desktop',
            fileSource: 'download',
        },
        notifications: [],
        offset: 0,
        total: 0,
        status: 'idle',
        error: null,
        isOverlay: false,
        isChanged: false,
    },
    reducers: {
        refreshTemplate(state, action) {
            state.current =  {
                name: 'Оффер1',
                    file: undefined,
                    type: 'desktop',
                    fileSource: 'download',
            };
        },
        addTemplateNotification(state, action) {
            const notification = {
                id: action.payload.id,
                message: action.payload.message,
                created: action.payload.created
            };

            state.notifications.unshift(notification);
        },
        updateTemplate(state, action) {
            state.current = { ...state.current, ...action.payload };

            if (action.payload.fileName) {
                state.current.file = {
                    ...state.current.file,
                    name: action.payload.fileName
                };
            }

            state.isChanged = true;
        },
        setIsOverlay(state, action) {
            state.isOverlay = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            // GET BY ID
            .addCase(getTemplateById.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getTemplateById.fulfilled, (state, action) => {
                state.status = 'succeeded';

                state.current = {
                    ...action.payload,
                };
            })
            .addCase(getTemplateById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // GET ALL
            .addCase(getTemplates.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getTemplates.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.list = action.payload;
            })
            .addCase(getTemplates.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // DELETE
            .addCase(deleteTemplate.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(deleteTemplate.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.list = state.list.filter((t) => t.id !== action.payload.id);
            })
            .addCase(deleteTemplate.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.notifications = state.notifications.concat(action.payload.data);
                state.offset = state.offset + 10;
                state.total = action.payload.total;
            })
            .addCase(getNotifications.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // CREATE API
            .addCase(createTemplate.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(createTemplate.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.current = {
                    ...action.payload,
                };
                state.isChanged = false;

            })
            .addCase(createTemplate.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // UPDATE API
            .addCase(updateTemplateApi.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(updateTemplateApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isChanged = false;
            })
            .addCase(updateTemplateApi.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.current = {
                    ...state.current,
                    fileId: action.payload.id,
                    file: action.payload,
                };
            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // FILE DELETE
            .addCase(deleteFile.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(deleteFile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.current = {
                    ...state.current,
                    file: undefined,
                    fileId: undefined,
                };
            })
            .addCase(deleteFile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});


export const getTemplates = createAsyncThunk('templates/list', async (data) => {
    return await templatesService.listTemplates(data);
});


export const getTemplateById = createAsyncThunk('templates/getById', async (id) => {
    return await templatesService.getById(id);
});

export const deleteTemplate = createAsyncThunk('templates/delete', async (id) => {
    await templatesService.deleteTemplate(id);
    return { id };
});

export const createTemplate = createAsyncThunk('templates/create', async (data) => {
    const template = await templatesService.createTemplate(data);

    history.push(`/update-template/${encryptId(template.id)}`);

    return template;
});

export const updateTemplateApi = createAsyncThunk('templates/update', async (_, thunkAPI) => {
    const state = thunkAPI.getState();

    const { id, name, file, type, linkUrl } = state.template.current;

    return await templatesService.updateTemplate(state.template.current.id, {
        id,
        name,
        type,
        fileId: file ? file.id : null,
        linkUrl
    });
});

export const getNotifications = createAsyncThunk('templates/notifications', async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    return await notificationService.getNotifications({ offset: state.template.offset, limit: 10 });
});


export const uploadFile = createAsyncThunk('files/create', async (data) => {
    return await fileService.uploadFile(data);
});

export const deleteFile = createAsyncThunk('files/delete', async (id) => {
    return await fileService.deleteById(id);
});

// Action creators are generated for each case reducer function
export const { updateTemplate, refreshTemplate, setIsOverlay, addTemplateNotification } = templateSlice.actions;

export const selectAllTemplates = state => state.template.list;

export const currentTemplate = state => state.template.current;


export default templateSlice.reducer;
