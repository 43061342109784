import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as FolderIcon } from '../assets/icons/folder.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg';

const FileChanger = ({ source = 'file', onChange, visible }) => {

    const [value, setValue] = useState(source);

    const handleClick = (v) => {
        setValue(v);
        onChange(v);
    };

    return visible && (
        <Container source={value}>
            <div onClick={() => handleClick('download')}>
                <DownloadIcon />
                Загрузка
            </div>
            <div testId="file-changer-files" onClick={() => handleClick('file')}>
                <FolderIcon />
                Файлы
            </div>
        </Container>
    );

};


const Container = styled.div`
    display: flex;
    
    div {
        cursor: pointer;
        display: flex;
        align-items: center;
        
        svg {
            margin: 5px;
        }
    }
    
    div:first-child {
        background: ${props => props.source === 'download' ? '#E6E6E6' : '#FFFFFF'};
        border: 1px solid #E6E6E6;
        border-radius: 15px 0px 0px 15px;
        padding: 15px 15px 15px 20px;
    }
    
    div:nth-child(2) {
        background: ${props => props.source === 'file' ? '#E6E6E6' : '#FFFFFF'};
        border: 1px solid #E6E6E6;
        border-radius: 0px 15px 15px 0px;
        padding: 15px 20px 15px 15px;
    }
`;


export default FileChanger;
