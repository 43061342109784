import { privateAxios } from "../api/axios";

const getNotifications = async (data = { offset: 0, limit: 10 }) => {
    const response =  await privateAxios.get(`notifications/list?offset=${data.offset}&limit=${data.limit}`);
    return response.data;
};


export default {
    getNotifications,
};
