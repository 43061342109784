import React, { useState } from "react";
import styled from "styled-components";
import { Row } from "antd";

import { ReactComponent as WidgetsIcon } from '../../assets/icons/profile-widget.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-down.svg';
import TelephoneWidget from "./Widgets/TelephoneWidget";
import { useSelector } from "react-redux";
import EmailWidget from "./Widgets/EmailWidget";
import TelegramWidget from "./Widgets/TelegramWidget";
import WhatsappWidget from "./Widgets/WhatsappWidget";
import CustomWidget from "./Widgets/CustomWidget";

const WidgetsSettings = ({ onSave }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [highlighted, setHighlighted] = useState(false);


    const { user } = useSelector((state) => state.user);

    return (
        <Container isOpen={isOpen} onClick={() => !isOpen && setIsOpen(!isOpen)}>
            <Header>
                <Row align="middle">
                    <WidgetsIcon />
                    <p>Настройка виджетов</p>
                </Row>
                <StyledArrowIcon onClick={() => setIsOpen(!isOpen)} className={isOpen ? 'opened' : ''} />
            </Header>
            <Content className={isOpen ? 'opened' : ''}>
                <Body>
                    <p>Подключите виджеты обратной связи и целевого действия на странице просмотра</p>
                    <TelephoneWidget onClick={setHighlighted}
                                     isHighlighted={highlighted === 'telephone'}
                                     user={user}
                                     onSave={onSave}
                    />
                    <TelegramWidget onClick={setHighlighted}
                                    isHighlighted={highlighted === 'telegram'}
                                    user={user}
                                    onSave={onSave}
                    />
                    <WhatsappWidget onClick={setHighlighted} isHighlighted={highlighted === 'whatsapp'} user={user} onSave={onSave} />
                    <EmailWidget onClick={setHighlighted} isHighlighted={highlighted === 'email'} user={user} onSave={onSave} />
                    <CustomWidget onClick={setHighlighted} isHighlighted={highlighted === 'custom'} user={user} onSave={onSave} />
                </Body>
            </Content>
        </Container>
    );
};

export default WidgetsSettings;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }
    
    svg {
        margin-right: 15px;
    }
`;

const Container = styled.div`
    background: #FFFFFF;
    border-radius: 25px;
    padding: 40px;
    cursor: ${props => props.isOpen ? 'inherit' : 'pointer'};
`;

const Content = styled.div`
    margin-left: 30px;
    display: none;
    opacity: 0;
    transition: opacity 600ms, visibility 600ms;
    
    &.opened {
        display: block;
        opacity: 1;
    }
`;

const Body = styled.div`
    margin-top: 20px;
    margin-left: 20px;
    max-width: 750px
    
`;

const StyledArrowIcon = styled(ArrowIcon)`
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease;
    transform: rotate(0deg);
    cursor: pointer;

    &.opened {
        transform: rotate(180deg);
    }

`;
