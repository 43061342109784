import React from "react";
import styled from "styled-components";

const ActiveProgress = ({ percents }) => {

    return (
        <Container percents={percents}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </Container>

    );

};

export default ActiveProgress;

const Container = styled.div`
    display: flex;
    
    span {
        margin: 0 2px;
        width: 5px;
        height: 5px;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        
        &:nth-child(1) {
            background: ${props => props.percents >= 20 ? '#FFFFFF' : ''};
        }
        
        &:nth-child(2) {
            background: ${props => props.percents >= 40 ? '#FFFFFF' : ''};
        }
        
        &:nth-child(3) {
            background: ${props => props.percents >= 60 ? '#FFFFFF' : ''};
        }
        
        &:nth-child(4) {
            background: ${props => props.percents >= 80 ? '#FFFFFF' : ''};
        }
        
        &:nth-child(5) {
            background: ${props => props.percents >= 95 ? '#FFFFFF' : ''};
        }
    }
    
`;
