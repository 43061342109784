import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import fileService from "../../services/file.service";

export const fileSlice = createSlice({
    name: 'file',
    initialState: {
        list: [],
        status: 'idle',
        error: null,
        selected: null,
    },
    reducers: {
        setFile(state, action) {
            state.selected = action.payload;
        },
        updateFile(state, action) {
            state.selected = {
                ...state.selected,
                ...action.payload
            };
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getFiles.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getFiles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.list = action.payload;
            })
            .addCase(getFiles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });

    }
});


export const getFilesList = state => state.file.list;
export const getSelectedFile = state => state.file.selected;


export const getFiles = createAsyncThunk('files/list', async () => {
    return await fileService.listFiles();
});

export const updateFileApi = createAsyncThunk('files/update', async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const { selected } = state.template.current;

    return await fileService.update(selected.id, selected);
});

// Action creators are generated for each case reducer function
export const { setFile, updateFile } = fileSlice.actions;


export default fileSlice.reducer;
