import { privateAxios } from "../api/axios";

const createGroup = async (data) => {
    const response = await privateAxios.post(`group`, {
        name: data.name,
        emails: data.emails,
    });

    return response.data;
};

const getAllMyGroups = async () => {
    const response = await privateAxios.get(`group/list`);

    return response.data;
};

const inviteToGroup = async (data) => {
    const response = await privateAxios.post(`group/invite`, {
        email: data.email,
        groupId: data.groupId
    });

    return response.data;
};

const listReceivedInvites = async () => {
    const response = await privateAxios.get(`group/invite/received`);

    return response.data;
};

const answerToGroup = async (data) => {
    await privateAxios.put(`group/answer`, {
        groupInvitationId: data.groupInvitationId,
        status: data.status
    });
};


const deleteGroup = async (id) => {
    await privateAxios.delete(`group/${id}`);
};

const deleteInvitation = async (id) => {
    await privateAxios.delete(`group/invitation/${id}`);
};

const leaveGroup = async (id) => {
    await privateAxios.delete(`group/leave/${id}`);
};

export default {
    createGroup,
    getAllMyGroups,
    inviteToGroup,
    listReceivedInvites,
    answerToGroup,
    deleteGroup,
    deleteInvitation,
    leaveGroup
};
