import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import MessagesTemplate from "./MessagesTemplate";
import { Spin } from "antd/es";
import { getCurrentUser, updateUser } from "../../redux/slices/userSlice";
import SubscribeSettings from "./SubscribeSettings";
import WidgetsSettings from "./WidgetsSettings";
import GroupSettings from "./Groups/GroupSettings";

const Profile = () => {
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCurrentUser());
    }, [dispatch]);

  const onUpdateUser = useCallback((data) => {
      dispatch(updateUser(data));
  }, [dispatch]);

  const afterUpdate = () => {
      dispatch(getCurrentUser());
  };


  return user ? (
      <Container>
          <Content>
              <SubscribeSettings user={user} afterUpdate={afterUpdate} />
              <Space />
              <MessagesTemplate onSave={onUpdateUser} />
              <Space />
              <WidgetsSettings onSave={onUpdateUser} />
              <Space />
              <GroupSettings onSave={onUpdateUser} />
          </Content>
      </Container>
  ) : <Spin />;
};


export default Profile;

const Content = styled.div`

`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 55px;
`;

const Space = styled.div`
    margin-bottom: 50px;
`;
