import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Checkbox, Input } from "antd";
import { useDebounce } from "use-debounce";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";

const TelephoneWidget = ({ user, onSave, isHighlighted, onClick }) => {

    const [isActive, setIsActive] = useState(user.telephoneWidgetIsActive);
    const [isChosen, setIsChosen] = useState(user.telephoneWidgetIsActive);
    const [number, setNumber] = useState(user.telephoneWidgetNumber);
    const [name, setName] = useState(user.telephoneWidgetName);
    const [isError, setIsError] = useState(false);

    const [numberD] = useDebounce(number, 500);
    const [isActiveD] = useDebounce(isActive, 500);
    const [nameD] = useDebounce(name, 500);

    useEffect(() => {
        onSave({
            telephoneWidgetIsActive: isActiveD,
            telephoneWidgetNumber: numberD,
            telephoneWidgetName: nameD
        });
    }, [numberD, nameD, isActiveD, onSave]);

    const isNumberEmpty = (number) => {
        return  !number || (number && !isValidPhoneNumber(number));
    };

    const handleCheckbox = () => {
        setIsChosen(!isChosen);

        if (!isActive && !isNumberEmpty(number)) {
            if (!isNumberEmpty(number)) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }

            setIsChosen(true);
        } else if (isActive) {
            setIsActive(false);
            setIsChosen(false);
            return;
        }
    };

    const handleOutside = () => {
        if (!isNumberEmpty(number)) {
            setIsActive(true);
            setIsError(false);
        } else {
            setIsActive(false);
            setIsError(true);
        }
        setIsChosen(true);
    };




    return (
        <Container onClick={() => onClick('telephone')} onBlur={() => onClick('')}>
            <Checkbox style={{ "minMidth": "180px" }} checked={isChosen} onChange={handleCheckbox}>Телефон</Checkbox>
            {
                (isChosen || isActive || !isNumberEmpty(number)) &&
                <>
                <PhoneStyledInput
                    onBlur={handleOutside}
                    error={isError}
                    isHighlighted={isHighlighted}
                    defaultCountry="RU"
                    placeholder="Введите номер"
                    value={number}
                    onChange={setNumber}
                />

                    <MessageInput value={name}
                                  onBlur={handleOutside}
                                  onChange={(e) => setName(e.target.value)}
                                  isHighlighted={isHighlighted}
                                  placeholder="Название кнопки"
                    />
                </>
            }
            {isActive && <p style={{ color: 'green' }}>Активно</p>}
            {((!isChosen && isNumberEmpty(number)) || !isActive) && <p style={{ color: 'red' }}>Неактивно</p>}
        </Container>
    );
};

export default TelephoneWidget;



const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    
    margin-top: 27px;
    
    .ant-checkbox-wrapper {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
`;

const PhoneStyledInput = styled(PhoneInput) `
    width: 100%;
    max-width: 220px;
    height: 40px;
    background: ${props => props.isHighlighted ? '#F4F4F4' : 'transparent'};
    border-radius: 10px;
    border: ${props => props.error ? '1px solid red !important' : '1px solid #f1f1f1 !important'};
    padding: 15px;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    
    margin-bottom: 10px;
    
    input {
      all: unset;
      
      ::placeholder { 
          color: #bfbfbf;
          opacity: 1;
        }
    }
`

const MessageInput = styled(Input)`
    background: ${props => props.isHighlighted ? '#F4F4F4' : 'transparent'};
    border-radius: 10px;   
    border: 1px solid #f1f1f1 !important;
    height: 40px;
    
    max-width: 200px;

`;
