const siteConfig = {
    backendUrl: process.env.REACT_APP_BACKEND_URL || 'https://api.unitoffer.com',
    viewerUrl: process.env.REACT_APP_VIEWER_URL || 'https://viewer.unitoffer.com',
    templateSecretKey: process.env.REACT_APP_SECRET_TEMPLATE_KEY || 'supersecretkey!',
    env: process.env.REACT_APP_ENV || 'production',
    botName: process.env.REACT_APP_BOT_NAME || 'UnitOfferBot',
    fileMaxSize: Number(process.env.REACT_APP_FILE_MAX_SIZE) || 100,
    AWSS3Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    AWSRegion: process.env.REACT_APP_AWS_REGION,
    AWSAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
    AWSSecretKey: process.env.REACT_APP_AWS_SECRET_KEY
};

export default siteConfig;
