import { privateAxios, publicAxios } from "../api/axios";

const createTemplate = async (data) => {
    const response = await privateAxios.post(`templates/create`, {
        name: data.name,
        fileId: data.fileId,
        type: data.type
    });

    return response.data;
};

const updateTemplate = async (id, data) => {
    const response = await privateAxios.put(`templates/${id}`, data);

    return response.data;
};

const listTemplates = async (data) => {
    const response = await privateAxios.post(`templates/list`, data);

    return response.data;
};

const getById = async (id) => {
    const response = await privateAxios.get(`templates/get/${id}`);

    return response.data;
};

const deleteTemplate = async (templateId) => {
  await privateAxios.delete(`templates/${templateId}`);

};

const updateAnalytics = async (templateId, data) => {
    await publicAxios.put(`public-templates/${templateId}/analytic`, data);
};

const getTemplateHtml = async (templateId) => {
    const response = await publicAxios.get(`public-templates/${templateId}`);

    return response.data;
};


export default {
    createTemplate,
    updateTemplate,
    listTemplates,
    getById,
    deleteTemplate,
    updateAnalytics,
    getTemplateHtml
};
