import React  from "react";
import Modal from "../ui-kit/Modal";
import UploadFileImage from "../assets/icons/onboarding_upload_file.png";
import SendOfferImage from "../assets/icons/onboarding_send_offer.png";
import FinalImage from "../assets/icons/onboarding_final.png";


import styled from "styled-components";
import KitButton from "../ui-kit/Button";
import {updateUser, updateUserOffline} from "../redux/slices/userSlice";
import {useDispatch} from "react-redux";

const headerStyle = (step => {
    if (step === 'INITIAL') {
        return "30px"
    } else if (step === 'TELEGRAM') {
        return "15px"
    }
});

const OnboardingModalWide = ({ visible, user, onClose, onboardingType }) => {
    const dispatch = useDispatch();

    const titleMap = {
        'UPLOAD_FILE': 'А теперь загрузите файл',
        'SEND_OFFER': 'Ура! Осталось лишь отправить',
        'FINAL': 'Поздравляем! Вам открыт бесплатный \n тестовый доступ.'
    }

    const descriptionMap = {
        'UPLOAD_FILE': `На следующем экране загрузите любое \n коммерческое предложение, презентацию, договор \n или счёт, которое требуется отправить клиенту. \n Вы можете использовать PDF, фото и даже ссылку.`,
        'SEND_OFFER': 'Остался один последний шаг — отправьте оффер \n любым удобным способом клиенту.\n Он откроет её в виде ссылки. А когда откроет, то вы \n получите уведомление и полную аналитику о том, \n сколько процентов и времени клиент был вовлечен.',
        'FINAL': 'Теперь вы можете отправлять офферы клиентам и \n управлять ими в разделе Офферы. \n Теперь можно использовать весь функционал сервиса.'
    }

    const iconStyle = () => {
        if (onboardingType === 'UPLOAD_FILE') {
            return { "top": "calc(50% - 134px)" }
        } else if (onboardingType === 'SEND_OFFER') {
            return { "top": "calc(50% - 135px)" }
        } else if (onboardingType === 'FINAL') {
            return { "top": "calc(50% - 145px)", "left": "0" }
        }
    }


    const renderIcons = () => {
        if (onboardingType === 'UPLOAD_FILE') {
            return (
                <img alt="Загрузите оффер" src={UploadFileImage} />
            )
        } else if (onboardingType === 'SEND_OFFER') {
            return (
                <img alt="Отправьте оффер" src={SendOfferImage} />
            )
        } else if (onboardingType === 'FINAL') {
            return (
                <img alt="Поздравляем" src={FinalImage} />
            )
        }
    }

    const renderButtons = () => {
        if (['UPLOAD_FILE', 'SEND_OFFER'].includes(onboardingType) ) {
            return (
                <StyledButton onboardingStep={onboardingType} onClick={onClose} color="black">
                    Хорошо
                </StyledButton>
            )
        } else if (onboardingType === 'FINAL') {
            return (
                <StyledButton onboardingStep={onboardingType} onClick={finishOnboarding} color="black">
                    Ура! Спасибо
                </StyledButton>
            )
        }
    }

    const finishOnboarding = () => {
        dispatch(updateUser({
            isOnboardingFinished: true
        }))

        dispatch(updateUserOffline({
            isOnboardingFinished: true
        }))

        onClose()
    }

    return (
        <StyledModal
            onboardingStep={onboardingType}
            maskClosable={false}
            closable={false}
            destroyOnClose
            visible={visible}
            onCancel={onClose}
            footer={[

            ]}
        >
            <IconContainer style={iconStyle()}>
                {renderIcons()}
            </IconContainer>
            <Content onboardingStep={onboardingType}>
                <Title>
                    {titleMap[onboardingType]}
                </Title>
                <Description>
                    {descriptionMap[onboardingType]}
                </Description>
                <Buttons>
                    {renderButtons()}
                </Buttons>
            </Content>
        </StyledModal>
    );
};

export default OnboardingModalWide;


const StyledModal = styled(Modal)`
    width: 762px !important;
    top: calc(50% - 200px);

    .ant-modal-content {
        height: 357px;
        width: 762px;
        overflow: initial !important;
        
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    .ant-modal-header {
        border-radius: 30px;
        margin-top: ${(props) => headerStyle(props.onboardingStep)};

        padding: 41px 30px 4px !important;
    }

    .ant-modal-body {
        padding: 6px 30px 15px !important;
    }
`

const Content = styled.div`
    margin-top: 50px;
    
    width: ${(props) => ['SEND_OFFER', 'FINAL'].includes(props.onboardingStep) ? `calc(100% - 280px)` : `calc(100% - 250px)`};
    
    float: right;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const IconContainer = styled.div`
    position: absolute;
`

const Title = styled.div`
    width: 100%;
    text-align: left;
    white-space: pre-line;
    font-size: 20px;
    font-weight: bold;
`

const Description = styled.div`
    margin-top: 20px;
    width: 100%;
    text-align: left;
    white-space: pre-line;
    font-weight: 300;
`

const Buttons = styled.div`
    width: 100%;
    float: left;
`

const StyledButton = styled(KitButton)`
    margin-top: ${(props) => props.onboardingStep === 'SEND_OFFER' ? `30px` : `50px`};

    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    height: 55px;
    width: 200px;

    span {
        font-size: 15px !important;
        font-weight: 500;
    }

    svg {
        color: white !important;
        width: 30px;
        height: 30px;
        margin-left: 40px;
    }
`;
