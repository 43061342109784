import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Row } from "antd";

import { ReactComponent as WidgetsIcon } from '../../../assets/icons/group-settings.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-down.svg';
import { useDispatch, useSelector } from "react-redux";
import { getGroups, getGroupsList } from "../../../redux/slices/groupSlice";
import GroupContainer from "./GroupContainer";
import NewGroup from "./NewGroup";

const GroupSettings = ({ onSave }) => {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);

    const groups = useSelector(getGroupsList);

    const handleGetGroups = useCallback(async () => {
        await dispatch(getGroups());
    }, [dispatch]);

    useEffect(() => {
        handleGetGroups();
    }, [handleGetGroups]);


    return (
        <Container isOpen={isOpen} onClick={() => !isOpen && setIsOpen(!isOpen)}>
            <Header>
                <Row align="middle">
                    <WidgetsIcon />
                    <p>Группы пользователей</p>
                </Row>
                <StyledArrowIcon onClick={() => setIsOpen(!isOpen)} className={isOpen ? 'opened' : ''} />
            </Header>
            <Content className={isOpen ? 'opened' : ''}>
                <Body>
                    {groups.owner && groups.owner.length > 0 &&
                        groups.owner.map((group) =>
                            <React.Fragment key={group.id}>
                                <GroupContainer isOwner={true} id={group.id} group={group} onSave={handleGetGroups} />
                            </React.Fragment>
                        )
                    }
                    {groups.member && groups.member.length > 0 &&
                        groups.member.map((group) =>
                            <React.Fragment key={group.id}>
                                <GroupContainer id={group.id} group={group} onSave={handleGetGroups} />
                            </React.Fragment>
                        )
                    }
                    <NewGroup onSave={handleGetGroups}/>
                </Body>
            </Content>
        </Container>
    );
};

export default GroupSettings;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }

    svg {
        margin-right: 15px;
    }
`;

const Container = styled.div`
    background: #FFFFFF;
    border-radius: 25px;
    padding: 40px;
    cursor: ${props => props.isOpen ? 'inherit' : 'pointer'};
`;

const Content = styled.div`
    margin-left: 30px;
    display: none;
    opacity: 0;
    transition: opacity 600ms, visibility 600ms;

    &.opened {
        display: block;
        opacity: 1;
    }
`;

const Body = styled.div`
    margin-top: 20px;
    margin-left: 20px;


`;

const StyledArrowIcon = styled(ArrowIcon)`
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease;
    transform: rotate(0deg);
    cursor: pointer;

    &.opened {
        transform: rotate(180deg);
    }

`;
