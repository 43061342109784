import siteConfig from "../config";
import { encryptId } from "./IdEncrypt";

// Если делаешь изменения тут, то не забудь сделать изменения в файле бэкенда
export const makeViewerUrl = (email, id, typeContent, typeView  = 'client') => {
    let type;

    if (typeContent === 'PDF') {
        type = typeView === 'client' ? 'c' : 'v';
    } else if (typeContent === 'LINK') {
        type = typeView === 'client' ? 'l' : 'lv';
    } else {
        type = typeView === 'client' ? 'o' : 'ov';
    }

    if (email && email.includes('.2gis.ru')) {
        const subDomain = siteConfig.env === 'production' ? '' : 'dev.';
        return `https://${subDomain}offer2gis.ru/${type}?i=${encryptId(id)}`;
    }

    return `${siteConfig.viewerUrl}/${type}?i=${encryptId(id)}`;
};
