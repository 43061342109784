import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input } from "antd";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, Modifier } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import { useDebounce } from 'use-debounce';
import Menu from "../../common/Menu";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";

const links = [
   { text: 'Вставить ссылку на оффер', value: 'offerUrl', url: 'offerUrl' },
   { text: 'Вставить текущую дату', value: 'currentDate', url: 'currentDate' },
];

const ERROR_MESSAGE = 'Вы удалили ссылку на оффер. Нажмите + справа сверху, чтобы вставить ссылку в любое место сообщения.';


const elements = {
    'templateLink': `<a href="offerUrl" className="rdw-mention-link"><span data-offset-key="51lf3-1-0 ${Math.random()}"><span
                   data-text="true">@offerUrl</span></span></a>`,
    'currentDate': `<a href="offerUrl" className="rdw-mention-link"><span data-offset-key="51lf3-1-0 ${Math.random()}"><span
                   data-text="true">@currentDate</span></span></a>`
};

const parseInputContent = (data) => {
    data = data.replace(/@offerUrl/g, elements.templateLink);
    let { contentBlocks, entityMap } = htmlToDraft(data);
    return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap));
};



const MessageBoxEditor = ({ title, type, onSave, user }) => {

    const getMessage = useCallback(() => {
        if (type === 'whatsapp')
            {return user.whatsappMessage;}

        if (type === 'email')
            {return user.emailMessage;}

        if (type === 'telegram')
            {return user.telegramMessage;}
    }, [user, type]);

    const getSubject = useCallback(() => {
        if (type === 'email')
            {return user.emailSubject;}
    }, [user, type]);

    const initialState = parseInputContent(getMessage());
    const [editorState, setEditorState] = useState(initialState);
    const [subject, setSubject] = useState(getSubject());

    const [messageD] = useDebounce(editorState.getCurrentContent().getPlainText(), 300);
    const [subjectD] = useDebounce(subject, 300);

    const [error, setError] = useState(getMessage() && getMessage().includes('@offerUrl') ? '' : ERROR_MESSAGE);

    const onEditorStateChange = (state) => {
        setEditorState(state);
    };

    const onSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    useEffect(() => {
        if (messageD !== initialState.getCurrentContent().getPlainText() || getSubject() !== subjectD) {

            let data;

            switch (type) {
                case 'whatsapp': {
                    data = {  whatsappMessage: messageD };
                    break;
                }
                case 'email': {
                    data = {  emailMessage:  messageD, emailSubject: subjectD };
                    break;
                }
                case 'telegram': {
                    data = { telegramMessage: messageD };
                    break;
                }
                default: {
                    break;
                }
            }

           if (!messageD.includes('@offerUrl')) {
               setError(ERROR_MESSAGE);
           } else {
               setError('');
           }

            onSave(data);
        }
        // eslint-disable-next-line
    }, [messageD, subjectD]);



    const addElementToTextArea = (data) => {
        let { contentBlocks, entityMap } = htmlToDraft(data);

        let contentState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
        );

        onEditorStateChange(EditorState.push(editorState, contentState, 'insert-fragment'));
    };


    const options =  [
        {
            name: 'Вставить ссылку на оффер',
            onClick: () => addElementToTextArea(elements.templateLink),
        },
        {
            name: 'Вставить текущую дату',
            onClick: () => addElementToTextArea(elements.currentDate),
        }
    ];

    return (
        <Container>
            <Title>{title}</Title>
            {type === 'email' && <SubjectField value={subject} onChange={onSubjectChange} />}
            <TextAreaContainer>
                <DivEditor>
                    <Editor
                        toolbarHidden
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={onEditorStateChange}
                        mention={{
                            separator: ' ',
                            trigger: '@',
                            suggestions: links,
                        }}
                    />
                </DivEditor>
                <StyledMenu
                    offset={[-10, -25]}
                    items={options}
                    width="230px"
                    renderButton={() => <div className="button-cont"><StyledOptionsBtn aria-label="options" type="text" icon={<PlusIcon />} /></div>}
                />
            </TextAreaContainer>
            <ErrorMessage>{error}</ErrorMessage>
        </Container>
    );
};

export default MessageBoxEditor;


const DivEditor= styled.div`
    .demo-wrapper {
        position: relative;
        display: block !important;
        min-height: 139px !important;
        width: 100% !important;
        background: #F4F4F4;
        border-radius: 10px;    
    }
    
    .demo-editor {
        min-height: 139px !important;
        width: 100% !important;
        height: 100% !important;
        overflow: auto;
        box-sizing: border-box;
        border: 1px solid #f1f1f1 !important;
        padding-left: 20px !important;
        border-radius: 10px !important;
        height: 80% !important;
    
        .DraftEditor-editorContainer {
            height: 100%
            width: 100% !important;
        }    
    }
`;


const TextAreaContainer = styled.div`
    position: relative;
    
     .button-cont {
        position: absolute;
        top: 10px;
        right: 10px;
    }
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
`;

const Title = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px;
`;

const SubjectField = styled(Input)`
    background: #F4F4F4;
    border-radius: 10px;   
    border: 1px solid #f1f1f1 !important;
    margin-bottom: 20px;
    height: 45px;
`;


const StyledOptionsBtn = styled(Button)`
  width: 35px;
  height: 35px;
  background: black;
  border-radius: 7px;
  z-index: 999;


  svg {
    width: 15;
    height: 15px;
  }

  &:focus,
  &:hover {
    background-color: #999999;
  }


  .default-utils-menu {
    min-width: 200px;
  }
`;

const StyledMenu = styled(Menu) `
    .menu-common-wrapper {
        width: 230px !important;
    }
`;

const ErrorMessage = styled.div`
    color: red;
    font-size: 15px;     
    padding-top: 10px; 
`;
