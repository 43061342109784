import axios from "axios";
import siteConfig from "../config";
import { privateAxios } from "../api/axios";

const API_URL = siteConfig.backendUrl;

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getCurrentUser = async () => {
  const response = await privateAxios.get("user");
  return response.data;
};

const updateUser = async (data) => {
  const response = await privateAxios.put("user", data);
  return response.data;
};

const deactivateTelegram = async (data) => {
  const response = await privateAxios.put("user/unsubscribe");
  return response.data;
};

const uploadAvatar = async (avatar) => {
  const form = new FormData();
  form.append('file', avatar);

  if (avatar) {
    await privateAxios.put(`user/avatar`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
};



export default {
  getPublicContent,
  getCurrentUser,
  updateUser,
  deactivateTelegram,
  uploadAvatar
};
