import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import groupService from "../../services/group.service";

export const groupSlice = createSlice({
    name: 'group',
    initialState: {
        myGroups: {
            owner: [],
            member: []
        },
        invites: [],
        receivedInvitations: [],
        status: 'idle'
    },
    reducers: {
        setGroup(state, action) {
            state.myGroup = action.payload;
        },
        addGroupNotification(state, action) {
            state.receivedInvitations.push(action.notification);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getGroups.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getGroups.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.myGroups = action.payload;
            })
            .addCase(getGroups.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createGroup.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(createGroup.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(createGroup.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getReceivedInvitations.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getReceivedInvitations.fulfilled, (state, action) => {
                state.status = 'succeeded';

                state.receivedInvitations = action.payload;
            })
            .addCase(getReceivedInvitations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const getGroupsList = state => state.group.myGroups;


export const getGroups = createAsyncThunk('group/list', async () => {
    return await groupService.getAllMyGroups();
});

export const createGroup = createAsyncThunk('group/create', async (data) => {
    return await groupService.createGroup(data);
});

export const deleteGroup = createAsyncThunk('group/delete', async (id) => {
    return await groupService.deleteGroup(id);
});

export const deleteInvitation = createAsyncThunk('group/invitation/delete', async (id) => {
    return await groupService.deleteInvitation(id);
});

export const getReceivedInvitations = createAsyncThunk('group/invite/received', async () => {
    return await groupService.listReceivedInvites();
});

export const inviteToGroup = createAsyncThunk('group/invite', async (data) => {
    return await groupService.inviteToGroup(data);
});

export const answerToInvitation = createAsyncThunk('group/answer', async (data) => {
    return await groupService.answerToGroup(data);
});

export const leaveGroup = createAsyncThunk('group/leave', async (id) => {
    return await groupService.leaveGroup(id);
});

// Action creators are generated for each case reducer function
export const { addGroupNotification } = groupSlice.actions;


export default groupSlice.reducer;
