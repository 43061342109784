import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "./types";

import AuthService from "../../services/auth.service";
import userService from "../../services/user.service";

import { history } from "../../helpers/history";

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      return Promise.reject(message);
    }
  );
};

export const register = (username, password) => (dispatch) => {
    return AuthService.register(username, password).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            // dispatch({
            //     type: LOGIN_FAIL,
            // });

            return Promise.reject(message);
        }
    );
};

export const getCurrentUser = (username, password) => (dispatch) => {
  return userService.getCurrentUser(username, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });

        return Promise.resolve();
      },
      (error) => {
        // const message =
        //     (error.response &&
        //         error.response.data &&
        //         error.response.data.message) ||
        //     error.message ||
        //     error.toString();

        dispatch({
          type: LOGIN_FAIL,
        });

        return Promise.reject();
      }
  );
};


export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });


  setTimeout(() => {
      history.push('/auth/login');
  }, 500);
};
