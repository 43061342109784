import { useCallback, useEffect } from 'react';

function useOutsideClick(visible, node, onClose) {
  const handleClickOutside = useCallback(
    (e) => {
      if (node.current && !node.current.contains(e.target)) {
        onClose();
      }
    },
    [onClose, node],
  );
  useEffect(() => {
    if (visible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible, handleClickOutside]);
}

export default useOutsideClick;
