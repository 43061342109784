import React from 'react';
import styled from "styled-components";
import { ReactComponent as UnitOfferLogo } from '../assets/icons/UnitOfferAuthLogo.svg';
import { Link } from "react-router-dom";



const AuthLayout = ({ children, isLight }) => {
    return (
        <Layout>
            <>
                <Link to="/">
                    <UnitOfferLogo style={{ "margin-bottom": "50px" }} />
                </Link>
                {children}
            </>
        </Layout>
    );
};

export default AuthLayout;


const Layout = styled.div`
    background: #efefef;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

