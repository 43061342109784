import React, { useState } from "react";
import Modal from "../ui-kit/Modal";
import {ReactComponent as ArrowIcon} from "../assets/icons/narrow.svg";
import {ReactComponent as InitialOnboardingIcon} from "../assets/icons/onboarding_initial.svg";
import {ReactComponent as TelegramOnboardingIcon} from "../assets/icons/onboarding_telegram.svg";

import styled from "styled-components";
import KitButton from "../ui-kit/Button";
import {getCurrentUser, updateUser} from "../redux/slices/userSlice";
import {useDispatch} from "react-redux";
import config from "../config";

const headerStyle = (step => {
    if (step === 'INITIAL') {
        return "40px"
    } else if (step === 'TELEGRAM') {
        return "15px"
    }
});

const OnboardingModal = ({ visible, user, onClose, onboardingType }) => {
    const dispatch = useDispatch();

    const [onboardingStep, setOnboardingStep] = useState(onboardingType)

    const titleMap = {
        'INITIAL': 'Ура! Создайте первый оффер.',
        'TELEGRAM': 'Для начала давай \n подключим Telegram'
    }

    const descriptionMap = {
        'INITIAL': `Создайте свой первый оффер \n и получите 14 дней тестового периода.`,
        'TELEGRAM': `Туда будут приходить уведомления \n от клиентов и вся статистика`
    }

    const iconStyle = () => {
        if (onboardingStep === 'INITIAL') {
            return {"top": "-60px"}
        } else if (onboardingStep === 'TELEGRAM') {
            return {"top": "-70px"}
        }
    }

    const createFirstOffer = () => {
        dispatch(updateUser({
            onboardingStep: 'TELEGRAM'
        }))

        setOnboardingStep('TELEGRAM')
    }

    const activateTelegram = () => {
        dispatch(updateUser({
            onboardingStep: 'CREATE_OFFER'
        }))

        window.open(`https://t.me/${config.botName}?start=${user.userId}`);

        setTimeout(() => {
            dispatch(getCurrentUser());
        }, 1000)

        onClose()
    }

    const renderIcons = () => {
        if (onboardingStep === 'INITIAL') {
            return (
                <InitialOnboardingIcon />
            )
        } else if (onboardingStep === 'TELEGRAM') {
            return (
                <TelegramOnboardingIcon />
            )
        }
    }

    const renderButtons = () => {
        if (onboardingStep === 'INITIAL') {
            return (
                <StyledButton onClick={createFirstOffer} color="black">
                    Начать
                    <ArrowIcon />
                </StyledButton>
            )
        } else if (onboardingStep === 'TELEGRAM') {
            return (
                <StyledButton onClick={activateTelegram} color="black">
                    Активировать
                    <ArrowIcon />
                </StyledButton>
            )
        }
    }

    return (
        <StyledModal
            title={titleMap[onboardingStep]}
            onboardingStep={onboardingStep}
            maskClosable={false}
            closable={false}
            destroyOnClose
            visible={visible}
            onCancel={onClose}
            footer={[

            ]}
        >
            <IconContainer style={iconStyle()}>
                {renderIcons()}
            </IconContainer>
            <Content>
                <Description>
                    <p>{descriptionMap[onboardingStep]}</p>
                </Description>
                {renderButtons()}
            </Content>
        </StyledModal>
    );
};

export default OnboardingModal;


const StyledModal = styled(Modal)`
    width: 400px !important;
    top: calc(50% - 150px);


    .ant-modal-content {
        height: 300px;
        width: 400px;
        overflow: initial !important;
        
        display: flex;
        flex-direction: column;
    }
    
    .ant-modal-header {
        border-radius: 30px;
        margin-top: ${(props) => headerStyle(props.onboardingStep)};

        .ant-modal-title {
            white-space: pre-line;
            font-size: 20px;
        }

        padding: 41px 30px 4px !important;
    }

    .ant-modal-body {
        padding: 6px 30px 15px !important;
    }
`

const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const IconContainer = styled.div`
    position: absolute;
    right: calc(50% - 60px);
    z-index: 99999999999999999999 !important;
`

const Description = styled.div`
    text-align: center;
    white-space: pre-line;
    font-weight: 300;
`

const StyledButton = styled(KitButton)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
    margin-bottom: 20px;
    height: 55px;
    width: 200px;

    span {
        font-size: 15px !important;
        font-weight: 500;
    }

    svg {
        color: white !important;
        width: 30px;
        height: 30px;
        margin-left: 40px;
    }
`;
