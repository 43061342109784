import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from "./reducers/auth";
import TemplateReducer from "./slices/templateSlice";
import FileReducer from "./slices/fileSlice";
import UserReducer from "./slices/userSlice";
import GroupReducer from "./slices/groupSlice";

export default configureStore({
    reducer: {
        auth: AuthReducer,
        template: TemplateReducer,
        file: FileReducer,
        user: UserReducer,
        group: GroupReducer
    }
});
