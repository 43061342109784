import React from "react";
import styled from 'styled-components';
import Trigger from 'rc-trigger';
import { useCallback, useRef, useState } from 'react';
import useOutsideClick from "../hooks/useOutsideClick";



const Menu = ({ items, renderButton, points, offset, popupClassName, width = '200px' }) => {
    const [visible, setVisible] = useState(false);

    const onOpen = useCallback((e) => {
        e.stopPropagation();
        setVisible(true);
    }, []);

    const onClose = useCallback(() => setVisible(false), []);

    const menuWrapperRef = useRef(null);

    useOutsideClick(visible, menuWrapperRef, onClose);


    const popup = useCallback(
        () => (
            <MenuWrapper width={width} ref={menuWrapperRef}>
                {items.map((el, index) => (
                    <React.Fragment key={el.name}>
                        <MenuItem
                            disabled={el.disabled}
                            onClick={(e) => {
                                if (el.onClick) {
                                    e.stopPropagation();
                                    onClose();
                                    el.onClick();
                                }
                            }}
                        >
                            <p variant="body2">{el.name}</p>
                        </MenuItem>
                    </React.Fragment>
                ))}
            </MenuWrapper>
        ),
        [menuWrapperRef, items, onClose, width],
    );

    return (
        <Trigger
            popupVisible={visible}
            popup={popup}
            popupAlign={{
                points: points || ['tl', 'bl'],
                offset: offset || [-60, 3],
            }}
            stretch="width"
            destroyPopupOnHide
            popupClassName={popupClassName}
        >
            <div onClick={onOpen}>{renderButton(visible)}</div>
        </Trigger>
    );
};



const MenuWrapper = styled.div`
  position: absolute;
  right: 0px;
  bottom: -35px;
  cursor: pointer;
  border-radius: 6px;
  background-color: white;
  width: ${props => props.width ? props.width : '200px'};
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 999;
  box-shadow: 0 2px 8px 0 rgba(0, 36, 46, 0.14);
`;

const MenuItem = styled.div`
   
  padding: 10px 24px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #F4F4F4;
  }


`;

export default Menu;
