import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Checkbox, Input } from "antd";
import { useDebounce } from "use-debounce";
import isEmail from 'validator/lib/isEmail';

const EmailWidget = ({ user, onSave, isHighlighted, onClick }) => {

    const [isActive, setIsActive] = useState(user.emailWidgetIsActive);
    const [isChosen, setIsChosen] = useState(user.emailWidgetIsActive);
    const [email, setEmail] = useState(user.emailWidgetAddress);
    const [name, setName] = useState(user.emailWidgetName);
    const [isError, setIsError] = useState(false);

    const [emailD] = useDebounce(email, 500);
    const [isActiveD] = useDebounce(isActive, 500);
    const [nameD] = useDebounce(name, 500);

    useEffect(() => {
        onSave({
            emailWidgetIsActive: isActiveD,
            emailWidgetAddress: emailD,
            emailWidgetName: nameD
        });
    }, [emailD, nameD, isActiveD, onSave]);

    const isValidEmail = (email) => {
        return  email && isEmail(email);
    };

    const handleCheckbox = () => {
        setIsChosen(!isChosen);

        if (!isActive && isValidEmail(email)) {
            if (isValidEmail(email)) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }

            setIsChosen(true);
        } else if (isActive) {
            setIsActive(false);
            setIsChosen(false);
            return;
        }
    };

    const handleOutside = () => {
        if (isValidEmail(email)) {
            setIsActive(true);
            setIsError(false);
        } else {
            setIsActive(false);
            setIsError(true);
        }
        setIsChosen(true);
    };


    return (
        <Container onClick={() => onClick('email')} onBlur={() => onClick('')}>
            <Checkbox style={{ "minWidth": "180px" }} checked={isChosen} onChange={handleCheckbox}>E-mail</Checkbox>
            {
                (isChosen || isActive || isValidEmail(email)) &&
                <>
                    <EmailInput
                        type="email"
                        isHighlighted={isHighlighted}
                        placeholder="Введите E-mail"
                        onBlur={handleOutside}
                        value={email}
                        error={isError}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <MessageInput value={name}
                                  onBlur={handleOutside}
                                  onChange={(e) => setName(e.target.value)}
                                  isHighlighted={isHighlighted}
                                  placeholder="Название кнопки"
                    />
                </>
            }
            {isActive && <p style={{ color: 'green' }}>Активно</p>}
            {((!isChosen && !email) || !isActive) && <p style={{ color: 'red' }}>Неактивно</p>}
        </Container>
    );
};

export default EmailWidget;



const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    
    margin-top: 27px;
    
   .ant-checkbox-wrapper {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
`;

const EmailInput = styled(Input)`
    background: ${props => props.isHighlighted ? '#F4F4F4' : 'transparent'};
    border-radius: 10px;   
    border: ${props => props.error ? '1px solid red !important' : '1px solid #f1f1f1 !important'};
    height: 40px;
    max-width: 200px;
`;

const MessageInput = styled(Input)`
    background: ${props => props.isHighlighted ? '#F4F4F4' : 'transparent'};
    border-radius: 10px;   
    border: 1px solid #f1f1f1 !important;
    height: 40px;
    max-width: 200px;
`;
