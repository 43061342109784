import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from 'react-router-dom';

import { login } from "../../redux/actions/auth";
import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "../../assets/icons/narrow.svg";
import KitButton from "../../ui-kit/Button";
import { Form, Spin } from "antd";
import Input from "../../ui-kit/Input";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { isLoggedIn } = useSelector(state => state.auth);

  const dispatch = useDispatch();


  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onSubmit = (e) => {
    setLoading(true);

    dispatch(login(username, password))
      .then((e) => {
        props.history.push("/templates");
        window.location.reload();
      })
      .catch((e) => {

        setError(e);
        setLoading(false);
      });
  };


  if (isLoggedIn) {
    return <Redirect to="/templates" />;
  }


  return (
    <LoginContainer onFinish={onSubmit} >
      <Title>
        Вход в аккаунт
      </Title>
      <EmailInput
        type="email"
        placeholder="E-mail"
        name="username"
        value={username}
        isError={error === 'Такого аккаунта нет.'}
        onChange={onChangeUsername}
      />

      <PasswordInput
        type="password"
        placeholder="Пароль"
        name="password"
        value={password}
        isError={error === 'Неверный пароль.'}
        onChange={onChangePassword}
      />

      <StyledButton htmlType="submit" disabled={loading} color="black" >
        Дальше
        {loading ? <Spin style={{ "margin-top": "5px" }} /> : <ArrowIcon />}
      </StyledButton>

    <Message>
      {error && error === 'Неверный пароль.'  ? <Error>{error}</Error> : 'Забыли пароль?'} <StyledLink to="/auth/forgot-password">Восстановить</StyledLink>
    </Message>
    <Message>
      {error && error === 'Такого аккаунта нет.'  && <Error>{error}</Error> } <StyledLink to="/auth/register">Регистрация</StyledLink>
    </Message>

    </LoginContainer>
  );
};

export default Login;


const LoginContainer = styled(Form)`
  width: 400px;
  height: 375px;
  background: #FFFFFF;
  border-radius: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;

  margin-top: 37px;
  margin-bottom: 35px;
`;

const EmailInput = styled(Input)`
    background: ${props => props.isHighlighted ? '#F4F4F4' : '#F4F4F4'};
    border-radius: 10px;
    border: ${props => props.isError ? '1px solid red !important' : '1px solid #f1f1f1 !important'};

    width: 340px;
    height: 55px;
    padding-left: 20px;
    margin-bottom: 10px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
      font-weight: 300;
      color: #000000;
    }

    &:focus {
      outline-color: ${props => props.isError ? 'red' : '#000000;'};
      outline: 1;
    }
`;

const PasswordInput = styled(Input)`
    background: ${props => props.isHighlighted ? '#F4F4F4' : '#F4F4F4'};
    border-radius: 10px;
    border: ${props => props.isError ? '1px solid red !important' : '1px solid #f1f1f1 !important'};

    width: 340px;
    height: 55px;
    padding-left: 20px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
      font-weight: 300;
      color: #000000;
    }


`;

const StyledButton = styled(KitButton)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 12px;
    width: 340px;

    span {
        font-weight: 600;
        line-height: 21px;
        font-size: 18px !important;
        padding-left: 5px;
 

    svg {
        color: white !important;
        width: 30px;
        height: 30px;
    }
`;

const Message = styled.div`
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
    text-align: center;

`;

const StyledLink = styled(Link)`
  color: #3D61FF;
`;

const Error = styled.span`
 font-weight: 300;
    font-size: 16px;
    line-height: 30px;

    text-align: center;
      color: red;
    `;
