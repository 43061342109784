import React, { useEffect, useRef } from "react";
import { Button, Table } from "antd";
import { ReactComponent as ActionIcon } from '../../../assets/icons/actions.svg';
import { ReactComponent as DotsIcon } from "../../../assets/icons/dots.svg";
import "./TemplatesTable.css";
import styled from "styled-components";
import { history } from "../../../helpers/history";
import Menu from "../../../common/Menu";
import ActiveProgress from "./ActiveProgress";
import { Link } from "react-router-dom";
import { encryptId } from "../../../helpers/IdEncrypt";
import { makeViewerUrl } from "../../../helpers/subdomainViewerUrl";

const TemplatesTable = ({ user, templates, visible, templateDelete }) => {

    const containerRef = useRef();
    const tableRef = useRef();


    const openViewerPage = (template) => {
        window.open(makeViewerUrl(user.email, template.id, template.type, 'view'), '_blank', 'noopener,noreferrer');
    };

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            key: 1,
            render: (n, record) => {
                return (
                    <Link
                        style={{ "color": "rgba(0, 0, 0, 0.85)" }}
                        onClick={() => user.userId !== record.userId && (record.fileId || record.linkUrl) && openViewerPage(record)}
                        to={user.userId === record.userId ? `/update-template/${encryptId(record.id)}` : false }>
                        {n}
                    </Link>);
            }
        },
        {
            title: 'Создан',
            width: 150,
            dataIndex: 'created',
            render: (d) => {
                let today = new Date(d);
                let day = today.getDate() + "";
                let month = (today.getMonth() + 1) + "";
                let year = today.getFullYear() + "";
                let hour = today.getHours() + "";
                let minutes = today.getMinutes() + "";

                day = checkZero(day);
                month = checkZero(month);
                year = checkZero(year);
                hour = checkZero(hour);
                minutes = checkZero(minutes);

                function checkZero(data){
                    if(data.length === 1){
                        data = "0" + data;
                    }
                    return data;
                }

                return day + "." + month + "." + year + " " + hour + ":" + minutes;
            },
            sorter: (a, b) => new Date(a) > new Date(b),
            key: 2,
        },
        {
            title: 'Время начала просмотра',
            width: 150,
            dataIndex: 'startViewTime',
            render: (d) => {
                if (d) {
                    let today = new Date(d);
                    let day = today.getDate() + "";
                    let month = (today.getMonth() + 1) + "";
                    let year = today.getFullYear() + "";
                    let hour = today.getHours() + "";
                    let minutes = today.getMinutes() + "";

                    day = checkZero(day);
                    month = checkZero(month);
                    year = checkZero(year);
                    hour = checkZero(hour);
                    minutes = checkZero(minutes);

                    function checkZero(data) {
                        if (data.length === 1) {
                            data = "0" + data;
                        }
                        return data;
                    }

                    return day + "." + month + "." + year + " " + hour + ":" + minutes;
                } else {
                    return '';
                }
            },
            sorter: (a, b) => new Date(a) > new Date(b),
            key: 2,
        },
        {
            title: 'Отправлено',
            width: 150,
            dataIndex: 'sentDate',
            render: (d) => {
                if (d) {
                    let today = new Date(d);
                    let day = today.getDate() + "";
                    let month = (today.getMonth() + 1) + "";
                    let year = today.getFullYear() + "";
                    let hour = today.getHours() + "";
                    let minutes = today.getMinutes() + "";

                    day = checkZero(day);
                    month = checkZero(month);
                    year = checkZero(year);
                    hour = checkZero(hour);
                    minutes = checkZero(minutes);

                    function checkZero(data) {
                        if (data.length === 1) {
                            data = "0" + data;
                        }
                        return data;
                    }

                    return day + "." + month + "." + year + " " + hour + ":" + minutes;
                } else {
                    return '';
                }
            },
            sorter: (a, b) => new Date(a) > new Date(b),
            key: 2,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            width: 200,
            render: (value, record) => {
                let status;
                let color;

                switch (value) {
                    case 'draft': {
                        status = 'Черновик';
                        color = '#8F8F8F';
                        break;
                    }
                    case 'sent': {
                        status = 'Отправлено';
                        color = '#4560FF';
                        break;
                    }
                    case 'viewed': {
                        status = 'Просмотрено';
                        color = '#44B74C';
                        break;
                    }
                    case 'action': {
                        status = 'Целевое действие';
                        color = '#E0513E';
                        break;
                    }
                    default: {
                        break;
                    }
                }


                return (<StatusTag color={color}>
                            <>
                                <p>{status}</p>
                                {value === 'viewed' && record.type === 'PDF' && <ActiveProgress percents={record.viewedPageInPercentage} /> }
                            </>
                        </StatusTag>);
            },
            key: 3,
        },
        {
            title: 'Способ отправки',
            dataIndex: 'sentType',
            render: (sentType) => {

                const sentTypeMap = {
                    'whatsapp': 'WhatsApp',
                    'mail': 'Email',
                    'copy': 'Ссылка',
                    'telegram': 'Telegram'
                };

                return sentType.map((value, i) => <SentTag key={i}><p>{sentTypeMap[value]}</p></SentTag>);
            },
            key: 4,
        },
        {
            title: 'Имя',
            dataIndex: 'recepientName',
            key: 5
        },
        {
            title: 'Телефон',
            dataIndex: 'recepientPhone',
            key: 6
        },
        {
            title: 'Почта',
            dataIndex: 'recepientEmail',
            key: 7
        },
        {
            title: 'Активность',
            dataIndex: 'viewedTimeInSeconds',
            render: (time, row) => {
                const minutes = Math.floor(time / 60);
                const seconds = time - minutes * 60;

                return seconds ? <p>{minutes} м. {seconds} c. ({row.viewedPageInPercentage}%)</p> : null;
            },
            key: 8
        },
        {
            title: 'Последняя сессия',
            dataIndex: 'sessionTimeInSeconds',
            render: (t) => `${t} секунд`,
            key: 10
        },
        {
            title: 'Количество сессий',
            dataIndex: 'amountSessions',
            key: 10
        },
        {
            dataIndex: 'actions',
            width: 50,
            title: () => <ActionIcon/>,
            render: (_, record) => {

                const MenuItems = [
                    {
                        name: 'Изменить',
                        onClick: () => history.push(`/update-template/${encryptId(record.id)}`),
                    },
                    {
                        name: 'Удалить',
                        onClick: () => templateDelete(record.id),
                    }
                ]

                if (record.fileId || record.linkUrl) {
                    MenuItems.unshift(
                        {
                            name: 'Отправить',
                            onClick: () => history.push(`/update-template/${encryptId(record.id)}?send=true`),
                        },
                    )
                }

                return (user.userId === record.userId) && <Menu
                    offset={[-20, 50]}
                    items={MenuItems}
                    renderButton={() => <div style={{ "display": "flex" }}><Button aria-label="options" type="text" icon={<DotsIcon />} /></div>}
                />;
            },
            key: 9,
            fixed: "right"
        }
    ];

    useEffect(function () {
        const table = document.querySelector('.ant-table-content');

        bindDragScroll(containerRef.current, table);
    }, []);

    function bindDragScroll(container, scroller) {

        let x = 0;
        // eslint-disable-next-line no-unused-vars
        let y = 0;

        let x2 = 0;
        let y2 = 0;
        // eslint-disable-next-line no-unused-vars
        let t = 0;

        container.addEventListener("mousedown", down);
        container.addEventListener("click", preventDefault);
        scroller.addEventListener("mousewheel", horizontalMouseWheel); // prevent macbook trigger prev/next page while scrolling

        function down(evt) {
            if (evt.button === 0) {

                t = Date.now();
                x = x2 = evt.pageX;
                y = y2 = evt.pageY;

                container.classList.add("down");
                window.addEventListener("mousemove", move);
                window.addEventListener("mouseup", up);

            }
        }

        function move(evt) {
            // alert("move");
            if (container.classList.contains("down")) {

                let _x = evt.pageX;
                let _y = evt.pageY;
                let deltaX = _x - x;

                scroller.scrollLeft -= deltaX;

                x = _x;
                y = _y;

            }

        }

        function up() {
            window.removeEventListener("mousemove", move);
            window.removeEventListener("mouseup", up);

            t = 0;

            container.classList.remove("down");
        }

        function preventDefault(evt) {
            if (x2 !== evt.pageX || y2 !== evt.pageY) {
                evt.preventDefault();
                return false;
            }
        }

        function horizontalMouseWheel(evt) {
            let x = scroller.scrollLeft;

            let max = scroller.scrollWidth - scroller.offsetWidth;
            let dir = (evt.deltaX || evt.wheelDeltaX);
            let stop = dir > 0 ? x >= max : x <= 0;
            if (stop && dir) {
                evt.preventDefault();
            }
        }

    }

    return visible && (
        <Container ref={containerRef}>
            <Table
                ref={tableRef}
                scroll={{ x: 1800 }}
                columns={columns}
                dataSource={templates}
                tableLayout="auto"
                className="custom-table-classname"
            />
        </Container>
    );
};

export default TemplatesTable;

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

const StatusTag = styled.div`
    background:  ${props => props.color};
    color: #FFFFFF;
    font-size: 12px;
    border-radius: 5px;
    width: 150px;
    height: 25px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    p {
        font-size: 12px;
        margin-right: 5px !important;;
    }
`;

const SentTag = styled.div`
    background: #545454;
    color: #FFFFFF;
    font-size: 12px;
    border-radius: 5px;
    height: 25px;
    margin: 0 5px;
        
    &:first-child {
        margin-left: 0;
    }
    
    &:last-child {
        margin-right: 0;
    }
    
    display: inline-block;
    
    p {
        margin: 0;
        padding: 3px 10px !important;
    }
`;
