import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userService from "../../services/user.service";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        error: null,
    },
    reducers: {
        updateUserOffline(state, action) {
            state.user = { ...state.user, ...action.payload };
        },
    },
    extraReducers(builder) {
        builder
            // GET CURRENT USER
            .addCase(getCurrentUser.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getCurrentUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload;
            })
            .addCase(getCurrentUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // UPDATE USER
            .addCase(updateUser.pending, (state, action) => {
                const { haveNewNotifications } = action.meta.arg;
                state.user.haveNewNotifications = haveNewNotifications;
                state.status = 'loading';
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });

    }
});


export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async () => {
    return await userService.getCurrentUser();
});

export const updateUser = createAsyncThunk('user/updateUser', async (data) => {
    return await userService.updateUser(data);
});

export const {updateUserOffline} = userSlice.actions


export default userSlice.reducer;
