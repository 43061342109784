import React, { useState } from "react";
import styled from "styled-components";
import { Row } from "antd";

import MessageBoxEditor from "./MessageBoxEditor";
import { ReactComponent as MessageTemplateIcon } from '../../assets/icons/message-template.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-down.svg';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const MessagesTemplate = ({ onSave }) => {

    const { user } = useSelector((state) => state.user);

    const search = useLocation().search;
    const isOpenParam = new URLSearchParams(search).get('isOpenParam');

    const [isOpen, setIsOpen] = useState(!!isOpenParam);

    const handleSave = (data) => {
        onSave({
            ...data,
            isMessageUpdated: true
        });
    };

    return (
        <Container isOpen={isOpen} onClick={() => !isOpen && setIsOpen(!isOpen)}>
            <Header>
                <Row align="middle">
                    <MessageTemplateIcon />
                    <p>Шаблоны сообщений</p>
                </Row>
                <StyledArrowIcon onClick={() => setIsOpen(!isOpen)} className={isOpen ? 'opened' : ''} />
            </Header>
            <Content className={isOpen ? 'opened' : ''}>
                <MessageBoxEditor user={user} data={{ message: user.whatsappMessage }}  onSave={handleSave} type="whatsapp" title="WhatsApp" />
                <MessageBoxEditor user={user} data={{ subject: user.emailSubject, message: user.emailMessage }}  onSave={handleSave} type="email" title="Email" />
                <MessageBoxEditor user={user} data={{ message: user.telegramMessage }} onSave={handleSave} type="telegram" title="Telegram" />

            </Content>
        </Container>
    );
};

export default MessagesTemplate;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }
    
    svg {
        margin-right: 15px;
    }
`;

const Container = styled.div`
    background: #FFFFFF;
    border-radius: 25px;
    padding: 40px;
    cursor: ${props => props.isOpen ? 'inherit' : 'pointer'};
`;

const Content = styled.div`
    margin-left: 30px;
    display: none;
    opacity: 0;
    transition: opacity 600ms, visibility 600ms;
    
    &.opened {
        display: block;
        opacity: 1;
    }
`;

const StyledArrowIcon = styled(ArrowIcon)`
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease;
    transform: rotate(0deg);
    cursor: pointer;

    &.opened {
        transform: rotate(180deg);
    }

`;
