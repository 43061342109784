import React, { useEffect, useRef, useState } from "react";
import { FileDrop } from 'react-file-drop';

import "./FileUploadModal.css";
import styled from "styled-components";
import AWS from 'aws-sdk';

import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist';

import { ReactComponent as PDFIcon } from '../../../assets/icons/pdf.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document.svg';
import { ReactComponent as ImageIcon } from '../../../assets/icons/image.svg';
import { ReactComponent as LinkURLIcon } from '../../../assets/icons/linkURL.svg';

import { ReactComponent as EyeGreyIcon } from '../../../assets/icons/eye-grey.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg';
import { ReactComponent as EyeActiveIcon } from '../../../assets/icons/eye-active.svg';

import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as EditHoverIcon } from '../../../assets/icons/edit-hover.svg';
import { ReactComponent as EditActiveIcon } from '../../../assets/icons/edit-active.svg';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as DeleteHoverIcon } from '../../../assets/icons/delete-hover.svg';
import { ReactComponent as DeleteActiveIcon } from '../../../assets/icons/delete-active.svg';


import {notification, Progress} from "antd";
import DynamicIcon from "../../../ui-kit/Icon";
import KitInput from "../../../ui-kit/Input";
import config from "../../../config";
import { urlFormat } from "../../../helpers/urlFormat";
import KitButton from "../../../ui-kit/Button";
import {isHttpsURL} from "../../../helpers/isHttpsURL";

import imageCompression from 'browser-image-compression';

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;

const S3_BUCKET = config.AWSS3Bucket;
const REGION = config.AWSRegion;

const ACCEPT_TYPES = 'image/bmp,application/pdf,text/csv,application/vnd.oasis.opendocument.text,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/gif,text/htm,text/html,image/jpg,image/jpeg,image/png,application/vnd.ms-powerpoint,applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation,image/tiff,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'


AWS.config.update({
    accessKeyId: config.AWSAccessKey,
    secretAccessKey: config.AWSSecretKey
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

const messages = [
    {
        "title": "Добавьте виджеты обратной связи в просмотрщик для клиента.",
        "description": "Можно активировать в Настройках."
    },
    {
        "title": "Подключите Telegram, чтобы получать уведомления в боте.",
        description: "Можно активировать в Настройках."
    },
    {
        title: "Составьте заранее готовые сопроводительные письма, чтобы не писать их каждый раз.",
        description: "Можно активировать в Настройках."
    }
];


const FileAndLinkUploadComponent = ({ onSave, onDelete, handleUpdate, template, setLinkIsError }) => {
    const [progress, setProgress] = useState(0);
    const [progressMessageIndex, setProgressMessageIndex] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isError, setIsError] = useState();
    const [link, setLink] = useState('');

    useEffect(() => {
        if (template.file) {
            setProgress(null);
        }
    },[template.file]);

    const handleFileName = (e) => {
        handleUpdate({
            fileName: e.target.value
        });
    };

    const handleLinkUrl = (e) => {
        if (!isHttpsURL(e.target.value)) {
            setLinkIsError(true);
        } else {
            setLinkIsError(false);
        }

        handleUpdate({
            linkUrl: e.target.value
        });
    };

    const deleteLinkUrl = () => {
        handleUpdate({
            type: null,
            linkUrl: null
        });
    }

    const linkInputRef = useRef(null);
    const fileInputRef = useRef(null);

    const uploadImageThumbnail = async (templateId, file) => {
        const options = {
            maxSizeMB: 0.05,
            maxWidthOrHeight: 100,
            useWebWorker: true,
        }

        const compressedFile = await imageCompression(file, options);

        const arr = compressedFile.name.split('.')
        arr[arr.length - 1] = 'png'
        const fileNameWithImageType = arr.join('.')

        console.log('compressedFile', compressedFile)

        const arrayBuffer = await compressedFile.arrayBuffer();
        const buffer = Buffer.from(arrayBuffer);

        const key =  urlFormat(`${templateId}_thumbmail_${fileNameWithImageType}`);

        console.log('templateId', templateId, key)

        const params = {
            Body: buffer,
            Bucket: S3_BUCKET,
            Key: key
        };

        await myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 10));
            })
            .send((err) => {
                if (err) {
                    setIsError(true);
                    setIsDragging(false);
                    notification.open({
                        message: 'Ошибка при загрузке файла.',
                        description: err.message
                    });

                }
            });


        return key;
    }

    const uploadPdfThumbnail = async (imageData, templateId, fileName) => {
        const buffer = new Buffer.from(imageData.replace('data:image/png;base64,', ""), 'base64');
        const fileNameWithImageType = fileName.replace(/.pdf/g, '.png');
        const key =  urlFormat(`${templateId}_thumbmail_${fileNameWithImageType}`);

        const params = {
            Body: buffer,
            Bucket: S3_BUCKET,
            Key: key
        };

        await myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 10));
            })
            .send((err) => {
                if (err) {
                    setIsError(true);
                    setIsDragging(false);
                    notification.open({
                        message: 'Ошибка при загрузке файла.',
                        description: err.message
                    });

                }
            });


        return key;
    };

    const addLink = async () => {
        console.log('addLink')
        handleUpdate({
            type: 'LINK',
            linkUrl: link
        });
    };

    const uploadFile = async (file) => {
        if ((file.size / 1024 / 1024) > config.fileMaxSize) {
            notification.open({
                message: 'Ошибка при загрузке файла.',
                description: `Максимальный размер файла ${config.fileMaxSize} Мб.`,
            });

            setIsDragging(false);
        } else {
            const interval = setInterval(() => {
                setProgressMessageIndex((prevValue) => prevValue === messages.length -1 ? 0 : prevValue + 1);
            }, 4000);

            console.log('file', file)

            let thumbnailKey;
            if (file.type === 'application/pdf') {
                const imageData = await getPdfThumbnail(file);
                thumbnailKey = await uploadPdfThumbnail(imageData, template.id, file.name);
            } else if (file.type.includes('image')) {
                thumbnailKey = await uploadImageThumbnail(template.id, file);
            }

            const key =  urlFormat(`${template.id}_${file.name}`);

            const params = {
                Body: file,
                Bucket: S3_BUCKET,
                ContentType: file.type,
                Key: key
            };

            return myBucket.putObject(params)
                .on('httpUploadProgress', (evt) => {
                    setProgress(Math.round(10 + (evt.loaded / evt.total) * 100));
                })
                .send((err) => {
                    if (err) {
                        clearInterval(interval);
                        setIsError(true);
                        setIsDragging(false);
                        notification.open({
                            message: 'Ошибка при загрузке файла.',
                            description: err.message
                        });

                        setTimeout(() => {
                            setIsError(undefined);
                            setProgress(null);
                        }, 2500);
                    } else {
                        clearInterval(interval);
                        onSave({
                            name: file.name,
                            size: file.size,
                            mimetype: file.type,
                            key,
                            thumbnailKey
                        });
                    }
                });
        }
    };

    const getPdfThumbnail = async (file) => {
        const fileData = await file.arrayBuffer();
        const pdf = await getDocument({ data: fileData }).promise;

        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1.5 });

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({
            canvasContext: ctx,
            viewport: viewport
        }).promise;

        return canvas.toDataURL('image/png');
    };

    const onTargetClick = () => {
        fileInputRef.current.click();
    };

     const onFileClick = (event) => {
         uploadFile(event.target.files[0]);
    };

    const onInputClick = (event) => {
        event.target.value = '';
    };

    const onDrop =  (files) => {
         uploadFile(files[0]);
    };


    const renderFileContent = () => {
        console.log('template', template)
        return template.file ? (
            <UploadedContent>
                <InputContainer>
                    {template.file.mimetype === 'application/pdf' ? <PDFIcon/> : template.file.mimetype.includes('image') ? <ImageIcon /> : <DocumentIcon />}
                    <StyledInput disabled={!isEditMode} onChange={handleFileName} value={template.file.name} />
                </InputContainer>
                <FileActions>
                    <DynamicIcon Initial={EyeGreyIcon} Hover={EyeIcon} Active={EyeActiveIcon} />
                    <DynamicIcon Initial={EditIcon} Hover={EditHoverIcon} Active={EditActiveIcon} onClick={() => setIsEditMode(true)} />
                    <DynamicIcon Initial={DeleteIcon} Hover={DeleteHoverIcon} Active={DeleteActiveIcon} onClick={() => onDelete(template.file.id)} />
                </FileActions>
            </UploadedContent>
        ) : null
    }

    const renderLinkContent = () => {
        return template.linkUrl ? (
            <UploadedContent>
                <InputContainer>
                    <LinkURLIcon/>
                    <StyledInput type="url" disabled={!isEditMode} onChange={handleLinkUrl} value={template.linkUrl} />
                </InputContainer>
                <FileActions>
                    <DynamicIcon testId="edit-name-icon" Initial={EditIcon} Hover={EditHoverIcon} Active={EditActiveIcon} onClick={() => setIsEditMode(true)} />
                    <DynamicIcon Initial={DeleteIcon} Hover={DeleteHoverIcon} Active={DeleteActiveIcon} onClick={deleteLinkUrl} />
                </FileActions>
            </UploadedContent>
        ) : null
    }

    const renderProgressContent = () => {
        return progress ? (
            <ProgressContainer>
                <Progress percent={progress} status={isError ? 'exception' : undefined} />
                <ProgressTitle>{messages[progressMessageIndex].title}</ProgressTitle>
                <ProgressDescription>{messages[progressMessageIndex].description}</ProgressDescription>
            </ProgressContainer>
        ) : null;
    }

    const uploadFileOrLinkContent = () => {
        return !template.linkUrl && !template.file && !progress && (
                <StyledFiledDrop
                    isDragging={isDragging}
                    onDrop={onDrop}
                    onDragOver={(e) => {setIsDragging(true);}}
                    onDragLeave={(e) => { setIsDragging(false);}}
                >
                    <UploadInput
                        ref={fileInputRef}
                        type="file"
                        accept={ACCEPT_TYPES}
                        name="document"
                        onChange={onFileClick}
                        onClick={onInputClick}
                        data-cy="uploadFile"
                    />
                    <Desciption>
                        {
                            isDragging ?
                                <>Отпустите файл!</>
                                :
                                <FileLinkContent>
                                    <span><span className="file-underline-click" onClick={onTargetClick}>Загрузите</span> PDF или любой другой документ, перетащив сюда.</span>
                                    <span>Или добавьте ссылку на любой сайт.</span>

                                    <LinkContainer>
                                        <LinkInput
                                            ref={linkInputRef}
                                            type="url"
                                            value={link}
                                            onChange={(e) => {setLink(e.target.value)}}
                                            placeholder="https://"
                                        />
                                        <LinkButton
                                            onClick={addLink}
                                            type="submit"
                                            disabled={!isHttpsURL(link)}
                                            color="black"
                                        >
                                            Добавить
                                        </LinkButton>
                                    </LinkContainer>
                                </FileLinkContent>
                        }
                    </Desciption>
                </StyledFiledDrop>

        )
    }

    return (
        <>
            {renderFileContent()}
            {renderLinkContent()}
            {renderProgressContent()}
            {uploadFileOrLinkContent()}
        </>
    )
};

export default FileAndLinkUploadComponent;

const FileLinkContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    
    .file-underline-click {
        text-decoration: underline; 
        color: #3D61FF;
    }
    
    span {
        font-weight: 400;
        font-size: 20px !important;
        line-height: 23px !important;
        padding-bottom: 7px;
        color: #333333;
    }
    
   
`;

const UploadedContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const InputContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    
    svg {
        margin-right: 10px;
    }
`;

const StyledInput = styled(KitInput)`
    height: 40px !important;
    width: 80% !important;
    background: ${props => props.disabled ? 'white !important' : ''};
    cursor: ${props => props.disabled ? 'default' : 'text'} !important;
    
    ${props => props.disabled ? 
    `
        &:hover,
        &:visited,
        &:focus {
            border: none !important;
            box-shadow: none !important;
        }
    ` : ''
}

`;

const ProgressContainer = styled.div`
    height: 100%;
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ProgressTitle = styled.div`
    margin-top: 15px;
    font-style: italic;
    text-align: center;
`;

const ProgressDescription = styled.div`
    font-style: italic;
    text-align: center;
`;


const FileActions = styled.div`
    display: flex;
    
    svg {
        margin: 5px;

    }
`;

const StyledFiledDrop = styled(FileDrop)`
    position: relative;
    background: ${props => props.isDragging ? 'lightgrey' : '#F4F4F4'};
    border-radius: 15px;
    height: 360px;
    width: 100%;
    

    display: flex;
    align-items: center;
    justify-content: center;

    .file-drop-target {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

`;

export const UploadInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
`;


const Desciption = styled.span`
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: black;
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black;
  }

`;

const LinkContainer = styled.div`
    margin-top: 20px;
    display: flex;
`;


const LinkInput = styled(KitInput)`
    height: 45px;
    background: #FFFFFF !important;
    width: 500px;
    border-radius: 10px 0 0 10px;
;`

const LinkButton = styled(KitButton)`
    width: 120px;
    height: 45px;
    border-radius: 0 10px 10px 0 !important;
    background: ${props => props.disabled ? '#D1D1D1' : '#313131'} !important;
    
    span {
        font-size: 18px !important;
        line-height: 10px !important;
        color: #FFFFFF;
    }
`;
