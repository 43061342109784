import React, { useState } from "react";
import styled from "styled-components";

const DynamicIcon = ({ Initial, Hover, Active, ...props }) => {

    const [isClicked, setIsClicked] = useState();

    return (
        <Container onMouseLeave={() => setIsClicked(false)} onClick={() => setIsClicked(true)} className={props.className}>
            {
                isClicked ?
                    <Active className="active" {...props} />
                    :
                    <>
                        <Initial className="icon" {...props} />
                        <Hover className="hover-icon" {...props} />
                    </>
            }
        </Container>
    );
};


export default DynamicIcon;

const Container = styled.div`

    .icon {
        color: grey;
    }
    
    .hover-icon {
        display: none;
    }

    &:hover {
        .icon {
            display: none;
        }
        
        .hover-icon {
            display: block;
        }
    }
`;
