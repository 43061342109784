import React, { useState } from "react";
import styled from "styled-components";

import { useDispatch } from "react-redux";
import KitInput from "../../../ui-kit/Input";
import KitButton from "../../../ui-kit/Button";
import { createGroup } from "../../../redux/slices/groupSlice";

const NewGroup = ({ onSave }) => {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [emails, setEmails] = useState('');

    const handleCreateGroup = async () => {
        const emailsArray = emails ? emails.split(',').map(el => el.trim()) : undefined;

        await dispatch(createGroup({
            name,
            emails: emailsArray
        }));

        await onSave();
    };


    return (
        <Container>
            <p>Вы можете создать группу пользователей и пригласить в неё других участников.<br/>Приглашения будут отправлены на почты пользователей и в личный кабинет.</p>
            <Body>
                <GroupNameInput value={name} onChange={(e) => setName(e.target.value)} placeholder="Введите название группы"></GroupNameInput>
                <UserInput value={emails} onChange={(e) => setEmails(e.target.value)} placeholder="Почты пользователей (через запятую)"></UserInput>
                <SubmitGroupButton onClick={handleCreateGroup}>Создать группу</SubmitGroupButton>
            </Body>
        </Container>
    );
};

export default NewGroup;

const Container = styled.div`
`;

const Body = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

const GroupNameInput = styled(KitInput)`
    margin-right: 15px;
`;

const UserInput = styled(KitInput)`
`;

const SubmitGroupButton = styled(KitButton)`
    margin-left: 15px;
    width: 170px;
`;

