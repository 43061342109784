import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { history } from "../helpers/history";


import { ReactComponent as ProfileIcon } from '../assets/icons/setting.svg';
import { ReactComponent as ProfileActiveIcon } from '../assets/icons/setting-active.svg';

import { ReactComponent as OffersIcon } from '../assets/icons/offers-black.svg';
import { ReactComponent as OffersActiveIcon } from '../assets/icons/offers-active.svg';
import { setIsOverlay } from "../redux/slices/templateSlice";
import { useDispatch } from "react-redux";
import { ReactComponent as PushIcon } from "../assets/icons/notification.svg";
import { isOffersPage } from "../helpers/isOffersPage";


export const Menu = ({ user, isHovered, isOverlay, handleNotifications }) => {

    const isProfileLink = () => history.location.pathname.includes('/profile');
    const isOffersLink = () => isOffersPage(history.location.pathname);

    const [ProfileI, setProfileI] = useState(isProfileLink() ? ProfileActiveIcon : ProfileIcon);
    const [OffersI, setOffersI] = useState(isOffersLink ? OffersActiveIcon : OffersIcon);
    const [PushI] = useState(PushIcon);

    const dispatch = useDispatch();

    useEffect(() => {
        history.listen((h) => {
            setProfileI(isProfileLink(h.pathname) ? ProfileActiveIcon : ProfileIcon);
            setOffersI(isOffersLink(h.pathname) ? OffersActiveIcon : OffersIcon);
        });
    }, []);

    const isHoveredOrIsOverlay = isHovered || isOverlay;

    return (
        <MenuContainer isHoveredOrIsOverlay={isHoveredOrIsOverlay}>
                <StyledLink active={isOffersLink() ? 1 : 0} isHoveredOrIsOverlay={isHoveredOrIsOverlay} to={"/templates"}>
                    <OffersI style={ isHoveredOrIsOverlay ? { "marginRight" :'10px' } : {} }  />
                    <h3>Офферы</h3>
                </StyledLink>

                <StyledLink active={isProfileLink() ? 1 : 0} isHoveredOrIsOverlay={isHoveredOrIsOverlay}
                            onClick={() => isOverlay && dispatch(setIsOverlay(false))}
                            className={isOverlay ? "overlay" : ''}
                            to={isOverlay ? "/profile?isOpenParam=true" : "/profile"}
                >
                    <ProfileI style={ isHoveredOrIsOverlay ? { "marginRight" :'10px' } : {} }  />
                    <h3>Настройки</h3>
                </StyledLink>

                <PushButton isHoveredOrIsOverlay={isHoveredOrIsOverlay}
                            className={isOverlay ? "overlay" : ''}
                            onClick={handleNotifications}
                >
                    <PushI style={ isHoveredOrIsOverlay ? { "marginRight" :'10px' } : {} }  />
                    <h3>Лента</h3>
                    {user.haveNewNotifications && <BlueCircle />}
                </PushButton>
        </MenuContainer>
    );
};

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    margin-top: 160px;
    pointer-events: ${props => props.isHoveredOrIsOverlay ? 'auto' : 'none'};;
    
    .overlay {
       z-index: 999;
       background: white;
       text-decoration: underline;
       border-radius: 20px;
    }
`;

const StyledLink = styled(Link)`
    padding: 20px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    text-decoration: none;
    
    svg,
    h3 {
        opacity: ${props => props.active ? 1 : 0.7}; 
    }
    
    &:hover { 
        svg,
        h3 {
            color: black;
            opacity: 1
        }
    }
    
    h3 {
        color: ${props => props.active ? 'black' : '#808080'}; 
        visibility: ${props => props.isHoveredOrIsOverlay ? 'visible' : 'hidden'};
        margin: 0 !important;
    }
`;

const PushButton = styled.div`
    cursor: pointer;
    position: absolute;
    bottom: 0;
    padding: 20px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    text-decoration: none;
    
    svg,
    h3 {
        opacity: ${props => props.active ? 1 : 0.7}; 
    }
    
    &:hover { 
        svg,
        h3 {
            color: black;
            opacity: 1
        }
    }
    
    h3 {
        color: ${props => props.active ? 'black' : '#808080'}; 
        visibility: ${props => props.isHoveredOrIsOverlay ? 'visible' : 'hidden'};
        margin: 0 !important;
    }
`;

const BlueCircle = styled.div`
    width: 10px;
    height: 10px;
    position: absolute;
    background: #3D61FF;
    top: 20px;
    left: 35px;
    border-radius: 50%;
`;
