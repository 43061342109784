import axios from "axios";
import siteConfig from "../config";
import { logout } from "../redux/actions/auth";
import store from '../redux/store';

const API_URL = siteConfig.backendUrl;

export const privateAxios = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

export const publicAxios = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

privateAxios.interceptors.request.use(
    (request) => {
        if (request.headers) {
            const token = localStorage.getItem('id_token');
            token && (request.headers['Authorization'] = `Bearer ${token}`);
        }

        return request;
    },
    (err) => {
        return Promise.reject(err);
    },
);

privateAxios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        const { dispatch } = store;
        dispatch(logout());
    } else {
        return Promise.reject(error);
    }
});
