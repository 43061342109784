import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Xarrow from "react-xarrows";
import TemplatesTable from "./components/TemplatesTable";
import CreateTemplateModal from "./components/CreateTemplateModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteTemplate, getTemplates, selectAllTemplates } from "../../redux/slices/templateSlice";
import KitButton from "../../ui-kit/Button";
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import { Spin } from "antd";
import SearchFilter from "../../common/SearchFilter";
import { getGroups } from "../../redux/slices/groupSlice";

import {ReactComponent as OnboardingCreateOfferIcon} from "../../assets/icons/onboarding_create_offer.svg";
import {updateUser, updateUserOffline} from "../../redux/slices/userSlice";
import {history} from "../../helpers/history";
import {encryptId} from "../../helpers/IdEncrypt";

const Templates = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading] = useState(false);
    const dispatch = useDispatch();
    const templates = useSelector(selectAllTemplates);
    const { user } = useSelector((state) => state.user);

    const loadingStatus = useSelector(state => state.template.status);


    useEffect(() => {
        dispatch(getTemplates());
        dispatch(getGroups());
        //eslint-disable-next-line
    }, [dispatch])


    useEffect(() => {
        if (user && templates && templates.length > 0 && !user?.isOnboardingFinished && ['CREATE_OFFER', 'UPLOAD_FILE', 'SEND_OFFER', 'FINAL'].includes(user?.onboardingStep)) {

            const template = templates[0]

            history.push(`/update-template/${encryptId(template.id)}`)
        }
    }, [templates, user])


    const onDeleteTemplate = (templateId) => {
        dispatch(deleteTemplate(templateId));
    };

    const createOffer = () => {
        if (user?.onboardingStep === 'CREATE_OFFER') {
            dispatch(
                updateUser({
                    onboardingStep: 'UPLOAD_FILE'
                })
            )
            dispatch(updateUserOffline({
                onboardingStep: 'UPLOAD_FILE'
            }))

            // dispatch(getCurrentUser());
        }
        setIsModalOpen(true)
    }



    return (
        <TemplatesContainer >
            <Header>
                <StyledButton id="button-create-offer-id" color="black" onClick={createOffer} >
                    <PlusIcon />
                    Cоздать оффер
                </StyledButton>
                <SearchFilter />
            </Header>
            <TemplatesBody>
            { isModalOpen && <CreateTemplateModal
                visible={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            /> }
            {templates && templates.length === 0 ?
                <EmptyTemplates>
                    {!user?.isOnboardingFinished && user?.onboardingStep === 'CREATE_OFFER' ?
                        (
                            <>
                                <Xarrow
                                    end="button-create-offer-id" //can be react ref
                                    start="onboarding-container-id" //or an id
                                />
                                <OnboardingContainer id="onboarding-container-id">
                                    <IconContainer>
                                        <OnboardingCreateOfferIcon />
                                    </IconContainer>
                                    <Title>
                                        Отлично! А теперь давай {"\n"}
                                        создадим первый оффер.
                                    </Title>
                                    <Description>
                                        Оффером может быть любая презентация,{"\n"}
                                        коммерческое предложение, счёт или {"\n"}
                                        договор. То есть всё, что ты иногда {"\n"}
                                        отправляешь своим клиентам.
                                    </Description>
                                </OnboardingContainer>
                            </>
)
                        : ( <span>Пока не создано<br/>ни одного оффера</span>)
                    }
                </EmptyTemplates>
                :
                isLoading || !user ? <Spin size="large"/> :
                    <TemplatesTable
                        templates={templates}
                        user={user}
                        visible={loadingStatus === 'succeeded'}
                        templateDelete={onDeleteTemplate}
                    />
            }
            </TemplatesBody>
        </TemplatesContainer>
    );
};

export default Templates;

const OnboardingContainer = styled.div`
    position: relative;
    width: 468px;
    height: 301px;
    background: #FFFFFF;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
    border-radius: 25px;
`;

const IconContainer = styled.div`
    position: absolute;
    top: -70px;
    left: calc(50% - 60px)
`

const Title = styled.div`
    margin-top: 60px;
    white-space: pre-line;
    
    color: black;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
`

const Description = styled.div`
    margin-top: 20px;
    white-space: pre-line;
    
    color: #000;
    text-align: center;
    font-size: 20px;
    font-family: Ubuntu;
    font-weight: 300;
    line-height: 130%;
`

const EmptyTemplates = styled.div`
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: 16px;
        line-height: 18px;
        color: #B9B9B9;
    }
`;

const StyledButton = styled(KitButton)`
    width: 200px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin-right: 10px;
    }
`;


const TemplatesBody = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
`;

export const TemplatesContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 55px;
  background: #F4F4F4;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
`;
