import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

export const UserInfo = ({ isOverlayOrIsHovered, openAccountInfo }) => {
    const { user } = useSelector((state) => state.user);

    return user ? (
        <Container onClick={openAccountInfo}>
            {
                user?.avatar?.url ?
                <Avatar src={user?.avatar?.url} />
                : <AvatarDiv>{user?.firstName?.charAt(0).toUpperCase()}</AvatarDiv>
            }
                <Info isOverlayOrIsHovered={isOverlayOrIsHovered}>
                    <span>{user.firstName}</span>
                    <span>{user.email}</span>
                </Info>
        </Container>
    ) : null;
};

const Container = styled.div`
    display: flex;
    align-items: center;
    
    div {
        display: flex;
        flex-direction: column;
        
        span:first-child {
            margin-left: 10px;
            font-weight: 500;
            font-size: 13px;
            line-height: 14px;
            color: #000000;
            white-space:nowrap;
        }
        
        span:nth-child(2) {
            margin-left: 10px;
            font-size: 9px;
            color: #8F8F8F;
            font-weight: 600;
            cursor: pointer;
        }
    }
`;

const Avatar = styled.img`
    border-radius: 50%;
    background: #f3f3f3;
    width: 35px;
    height: 35px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    object-fit: cover
`;

const AvatarDiv = styled.div`
    border-radius: 50%;
    background: #f3f3f3;
    width: 35px;
    height: 35px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    object-fit: cover
`;

const Info = styled.div`
    visibility: ${props => props.isOverlayOrIsHovered ? 'visible' : 'hidden'};
    
    span:nth-child(2) {
        -webkit-mask-image: linear-gradient(270deg, transparent 15px, red 40px);
    }
`;



