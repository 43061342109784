import { publicAxios } from "../api/axios";

const register = (email, password) => {
  return publicAxios.post("register", {
    email,
    password,
  })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("id_token", response.data.token);
        }

        return response.data;
      });
};

const login = (email, password) => {
  return publicAxios
    .post("login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("id_token", response.data.token);
      }

      return response.data;
    });
};

const forgotPassword = (email) => {
        return publicAxios.put("forgot-password", {
            email,
        })
        .then((response) => {
            return response.data;
        })
            .catch(error => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                return Promise.reject(message);
            });
};

const newPassword = (data) => {
    return publicAxios.put("new-password", data)
        .then((response) => {
            return response.data;
        })
        .catch(error => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("id_token");
};

export default {
    register,
    login,
    logout,
    forgotPassword,
    newPassword
};
