import React from 'react';
import styled from "styled-components";

const Notification = ({ notification, handleAnswer }) => (

    <Container>
        <Text>
            <span role="img" aria-label="Notification">🔔</span> {notification.group.owner.firstName} {notification.group.owner.lastName} приглашает вас в группу {notification.group.name}
        </Text>
        <Footer>
            <Accept onClick={() => handleAnswer(notification.id, 'accepted')}>Принять</Accept>
            <Decline onClick={() => handleAnswer(notification.id, 'declined')}>Отклонить</Decline>
            <Date>Сейчас</Date>
        </Footer>
    </Container>
);

export default Notification;

const Container = styled.div`
    width: 305px;
    min-height: 115px;
    height: fit-content;
    left: 195px;
    top: 116px;
    
    background: #FFFFFF;
    border-radius: 15px;
    
    margin-bottom: 10px;
`;

const Text = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    
    padding: 15px 13px 19px 15px;
`;

const Footer = styled.div`
    position: relative;
    width: 100%;
    height: 32px;
    
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    
`;

const Accept = styled.div`
    position: absolute;
    bottom: 10px;
    left: 15px;
    cursor: pointer;
`;

const Decline = styled.div`
    position: absolute;
    bottom: 10px;
    left: 100px;
    cursor: pointer;
`;

const Date = styled.div`
    position: absolute;
    bottom: 10px;
    right: 15px;
    cursor: pointer;
        color: #8F8F8F;

`;
