import React, { useState } from "react";
import { ReactComponent as DeleteHoverIcon } from "../../../assets/icons/delete.svg";
import styled from "styled-components";
import KitInput from "../../../ui-kit/Input";
import KitButton from "../../../ui-kit/Button";
import { useDispatch, useSelector } from "react-redux";
import { deleteGroup, deleteInvitation, inviteToGroup, leaveGroup } from "../../../redux/slices/groupSlice";

const typeMapper = (status) => {
    switch (status) {
        case 'accepted':
            return {
                name: 'Участник',
                color: '#1AB800'
            };
        case 'sent':
            return {
                name: 'Приглашение отправлено',
                color: '#30a074'
            };
        case 'new_user': {
            return {
                name: 'Пользователь приглашен в UnitOffer',
                color: '#C12A25'

            };
        }
        case 'declined': {
            return {
                name: 'Приглашение отклонено',
                color: 'red',
            };
        }
        default: {
            return {
                name: undefined,
                color: undefined,
            };
        }
    }
};

const GroupContainer = ({ group, onSave, isOwner }) => {
    const dispatch = useDispatch();
    const [emails, setEmails] = useState('');

    const [invitingStatus, setInvitingStatus] = useState(false);

    const { user } = useSelector((state) => state.user);

    const handleDelete = async () => {
        await dispatch(deleteGroup(group.id));
        await onSave();
    };

    const handleInvite = async () => {
        const emailsArray = emails ? emails.split(',').map(el => el.trim()) : undefined;

        await Promise.all(emailsArray.map(email =>
            dispatch(inviteToGroup({
                email,
                groupId: group.id
            }))
        ));

        await onSave();

    };

    const handleDeleteInvitation = async (invitationId) => {
        if (isOwner) {
            await dispatch(deleteInvitation(invitationId));
        } else {
            await dispatch(leaveGroup(group.id));
        }

        await onSave();
    };

    return (
        <Container>
            <GroupName>{group.name}</GroupName>
            <Row>
                <RowName>{group.owner.firstName} {group.owner.lastName}</RowName>
                <RowEmail>{group.owner.email}</RowEmail>
                <RowType color="#3D61FF">Админ{isOwner && <DeleteHoverIcon onClick={handleDelete} />}</RowType>
            </Row>
            {group.invitations.map((invitation) => {
                return (
                    <Row key={invitation.id} id={invitation.id}>
                        <RowName>{invitation.user?.firstName} {invitation.user?.lastName}</RowName>
                        <RowEmail>{invitation.email}</RowEmail>
                        <RowType color={typeMapper(invitation.status).color}>
                            {typeMapper(invitation.status).name}
                            {(isOwner || (invitation?.user?.userId === user?.userId)) && <DeleteHoverIcon onClick={() => handleDeleteInvitation(invitation.id)} />}
                        </RowType>
                    </Row>
                );
            })}

            {isOwner && <AddUserButton onClick={() => setInvitingStatus((prevValue) => !prevValue)}>
                {invitingStatus ?
                    'Отменить' :
                    '+ Добавить ещё пользователей'
                }
            </AddUserButton>}

            {isOwner && invitingStatus &&
                <InvitingContainer>
                    <UserInput placeholder="Почты пользователей (через запятую)" value={emails} onChange={(e) => setEmails(e.target.value)} />
                    <SubmitUserButton onClick={handleInvite}>Добавить</SubmitUserButton>
                </InvitingContainer>
            }
        </Container>
    );
};

export default GroupContainer;


const Container = styled.div`
    margin-bottom: 60px;
`;

const GroupName = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    
    margin-bottom: 22px;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
`;

const RowName = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    min-width: 200px;
`;

const RowEmail = styled.div`
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    
    color: #8F8F8F;
    
    min-width: 200px;
`;

const RowType = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    min-width: 300px;
    
    color: ${(props) => props.color};
    
    svg {
        margin-left: 10px;
        cursor: pointer;
    }
`;

const AddUserButton = styled.button`
    background: none;
    border: none;
        
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-decoration-line: underline;
    text-underline-offset: 2px;
    cursor: pointer;
    
    margin-top: 26px;
`;

const InvitingContainer = styled.div`
    margin-top: 10px;
    display: flex;
`;

const UserInput = styled(KitInput)`

`;

const SubmitUserButton = styled(KitButton)`
    margin-left: 15px;
    width: 170px;
`;
