import React, { useEffect, useState } from "react";
import Modal from "../../../ui-kit/Modal";
import styled from 'styled-components';

import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { ReactComponent as WhatsappIcon } from '../../../assets/icons/whatsapp.svg';
import { ReactComponent as WhatsappHoverIcon } from '../../../assets/icons/whatsapp-hover.svg';

import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as MailHoverIcon } from '../../../assets/icons/mail-hover.svg';

import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram.svg';
import { ReactComponent as TelegramHoverIcon } from '../../../assets/icons/telegram-hover.svg';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-right.svg';

import templatesService from "../../../services/templates.service";
import { useSelector } from "react-redux";
import KitButton from "../../../ui-kit/Button";
import KitInput from "../../../ui-kit/Input";
import isEmail from "validator/lib/isEmail";
import Input from "../../../ui-kit/Input";
import { Tooltip } from "antd";
import { makeViewerUrl } from "../../../helpers/subdomainViewerUrl";

let parsedForWhatsapp = (phone) => {
    return phone.replace('+', '').replace(/ /g, '');
};


function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
}

const SendTemplateModal = ({ visible, onClose, template }) => {
    const { user } = useSelector((state) => state.user);

    const templateUrl = makeViewerUrl(user.email, template.id, template.type, 'client');

    const [type, setType] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (template) {
            setPhoneNumber(template.recepientPhone);
            setName(template.recepientName);
            setEmail(template.recepientEmail);
        }
    }, [template]);

    const onChange = (value, type) => {
        console.log('value', value)
        if (type === 'phone') {
            if (value && !isValidPhoneNumber(value)) {
                setIsError(true);
            } else {
                setIsError(false);
            }

            setPhoneNumber(value);
        } else if (type === 'mail') {
            setEmail(value);
        }
    };

    const handleBack = () => {
        setType(null);
    };

    const handleClose = () => {
        // if (!user.isMessageUpdated) {
        //     // dispatch(setIsOverlay(true));
        // }
        onClose({ isOnboarding: true });
    };

    const sendWhatsappMessage = () => {
        const message = user.whatsappMessage.replace(/@offerUrl/g, templateUrl).replace(/@currentDate/g, new Date().toLocaleDateString());
        let nm = message.replace(/(?:\r\n|\r|\n)/g, ' %0a');

        templatesService.updateTemplate(template.id, {
            status: 'sent',
            sentType: 'whatsapp',
            sentDate: new Date()
        });

        window.open(`https://wa.me/${parsedForWhatsapp(phoneNumber)}?text=${nm}`);
    };

    const sendTelegramMessage = () => {
        const message = user.telegramMessage.replace(/@offerUrl/g, encodeURIComponent(templateUrl)).replace(/@currentDate/g, new Date().toLocaleDateString());

        templatesService.updateTemplate(template.id, {
            status: 'sent',
            sentType: 'telegram',
            sentDate: new Date()
        });

        window.open(`tg://msg_url?url=&text=${message}`);
    };

    const sendEmailMessage = () => {
        const subject = user.emailSubject;
        const message = user.emailMessage.replace(/@offerUrl/g, templateUrl).replace(/@currentDate/g, new Date().toLocaleDateString());
        let nm = message.replace(/(?:\r\n|\r|\n)/g, ' %0a');

        templatesService.updateTemplate(template.id, {
            status: 'sent',
            sentType: 'mail',
            sentDate: new Date()
        });

        copyToClipboard(nm);
        window.open(`mailto:${email}?subject=${subject}&body=${nm}`);
    };

    const handleCopy = () => {
        copyToClipboard(templateUrl);
        setType('copy');
        templatesService.updateTemplate(template.id, {
            status: 'sent',
            sentType: 'copy',
            sentDate: new Date()
        });
    };

    const renderSendOptions = () => {
        return (
            <SendTemplateModalContainer>
                {/*<Title>Выберите способ</Title>*/}
                <Description>Куда хотите отправить ссылку на оффер?</Description>
                <TypeChanger>
                    <Tooltip overlayClassName="tooltip-classname" title={!phoneNumber || isError ? "Нужно указать номер" : ""}>
                        <WhatsappContainer disabled={!phoneNumber || isError} onClick={(phoneNumber && !isError) && sendWhatsappMessage}>
                            <WhatsappIcon className="wa-icon"/>
                            <WhatsappHoverIcon className="wa-icon-hover" />
                            <p>Отправить в WhatsAPP</p>
                        </WhatsappContainer>
                    </Tooltip>
                    <Tooltip overlayClassName="tooltip-classname" title={!email || !isEmail(email) ? "Нужно указать e-mail" : ""}>
                        <MailContainer disabled={!email || !isEmail(email)} onClick={email && sendEmailMessage}>
                            <MailIcon className="mail-icon" />
                            <MailHoverIcon className="mail-icon-hover" />
                            <p>Отправить через почту</p>
                        </MailContainer>
                    </Tooltip>
                        <TelegramContainer  onClick={sendTelegramMessage}>
                            <TelegramIcon className="telegram-icon"/>
                            <TelegramHoverIcon className="telegram-icon-hover" />
                            <p>Отправить в Telegram</p>
                        </TelegramContainer>
                </TypeChanger>
                <Footer>
                    <KitButton type="primary" onClick={handleCopy} style={{ "margin": "10px 0" }}>{type === 'copy' ? 'Ссылка скопирована' : 'Копировать ссылку'}</KitButton>
                    <BackButton onClick={handleBack}><><ArrowIcon/>Назад</></BackButton>
                </Footer>
            </SendTemplateModalContainer>
        );
    };

    const handleContactInfo = () => {
        templatesService.updateTemplate(template.id, {
            recepientEmail: email,
            recepientPhone: phoneNumber,
            recepientName: name,
        });

        setType('send');
    };

    return (
        <Modal
            title="Укажите контакты получателя"
            maskClosable
            closeIcon
            destroyOnClose
            visible={visible}
            onCancel={handleClose}
            footer={null}

        >
            {
                !type ?
                    <SendTemplateModalContainer>
                        <Description>Дальше можно выбрать способ отправки</Description>
                        <InputName value={name} onChange={(e) => setName(e.target.value)} placeholder="Имя *"/>
                        <PhoneStyledInput
                            defaultCountry="RU"
                            placeholder="Телефон"
                            value={phoneNumber}
                            onChange={(val) => onChange(val, 'phone')}
                        />
                        <StyledInput
                            type="email"
                            style={{ "margin-bottom": "10px" }}
                            value={email}
                            onChange={(e) => onChange(e.target.value, 'mail')}
                            placeholder="E-mail"
                        />
                        <Tooltip title={!name ? "Нужно указать имя" : ""}>
                        <Footer>
                            <NextButton disabled={!name || isError} color={(!name || isError) ? undefined : 'black'} onClick={handleContactInfo}>Далее</NextButton>
                        </Footer>
                        </Tooltip>
                    </SendTemplateModalContainer>
                    :
                    <>
                        {renderSendOptions()}
                    </>
            }
        </Modal>
    );
};

export default SendTemplateModal;

const InputName = styled(KitInput)`
    border: ${props => props.isError ? '1px solid red !important' : '1px solid #f1f1f1 !important'};
    margin-bottom: 10px;
`;

const BackButton = styled(KitButton)`
    padding: 0;
    span {
        svg {
            transform: rotate(180deg);
            margin-right: 10px;
        }
`;

const NextButton = styled(KitButton)`
`;

const PhoneStyledInput = styled(PhoneInput) `
    width: 100%;
    height: 50px;
    background: #F4F4F4 !important;
    border-radius: 10px;
    border: 1px solid #F4F4F4 !important;
    padding: 15px;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px;
    
    &:focus-within {
        border: 2px solid #000000 !important;
    }
   
    input {
      all: unset;
      width: 100%;
      height: 50px;
      
      ::placeholder { 
          color: #bfbfbf;
          opacity: 1;
        }
    }
`

const StyledInput = styled(Input) `
    border: ${props => props.isError ? '1px solid red !important' : '1px solid #f1f1f1 !important'};

    &:focus {
      outline-color: ${props => props.isError ? 'red' : '#000000;'} !important;
      outline: 1 !important;
    }
`;

const Footer = styled.div`
    width: 100%;
    margin-top: 15px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center
`;

const WhatsappContainer = styled.div`
    padding-top: 23px;

    ${props => props.disabled ? `
        .wa-icon-hover {
            display: none;
        };
        opacity: 0.5;
        cursor: no-drop !important;
    ` : `

    &:hover {
        background: #ECECEC;
        border-radius: 10px;

        .wa-icon {
            display: none
        }

        .wa-icon-hover {
            display: block;
        }
    }

    .wa-icon-hover {
        display: none;
    }
    ` }


`;

const MailContainer = styled.div`
    padding-top: 23px;
  ${props => props.disabled ? `
    .mail-icon-hover {
        display: none;
    }

    cursor: no-drop !important;
    opacity: 0.5;
  ` : `

      &:hover {
        background: #ECECEC;
        border-radius: 10px;

        .mail-icon {
            display: none
        }

        .mail-icon-hover {
            display: block;
        }
    }

    .mail-icon-hover {
        display: none;
    }

  `};


`;

const TelegramContainer = styled.div`
    padding-top: 23px;
    
      ${props => props.disabled ? `
        .telegram-icon-hover {
            display: none;
        }
        
        cursor: no-drop !important;
        opacity: 0.5;
      ` : `
       &:hover {
        background: #ECECEC;
        border-radius: 10px;

        .telegram-icon {
            display: none
        }

        .telegram-icon-hover {
            display: block;
        }
    }

    .telegram-icon-hover {
        display: none;
    }
      
      ` }

   
`;


const Description = styled.span`
    font-size: 18px;
    margin-bottom: 10px;
`;

const SendTemplateModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TypeChanger = styled.div`
    position: relative;
    display: flex;

    div {
        margin: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            margin-top: 5px !important;
            text-align: center;
            font-size: 14px;
        }
    }
`;
