import React from 'react';
import styled from "styled-components";

const OfferNotification = ({ notification }) => {

    let formattedMessage = notification.message.replace(/\n/g, "<br />").replace(/<b>/g, '<span>').replace(/<\/b>/g, '<span/>').trim().replace(/^<br \/>/, '');

    return (
        <Container>
            <Text>
                <div className="content"
                     dangerouslySetInnerHTML={{ __html: formattedMessage }}></div>
            </Text>
            <Footer>
                <DateBlock>{new Date(notification.created).toLocaleString()}</DateBlock>
            </Footer>
        </Container>
    );
};

export default OfferNotification;

const Container = styled.div`
    width: 305px;
    min-height: 115px;
    height: fit-content;
    left: 195px;
    top: 116px;

    background: #FFFFFF;
    border-radius: 15px;

    margin-bottom: 10px;
`;

const Text = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #000000;

    padding: 15px 13px 19px 15px;
`;

const Footer = styled.div`
    position: relative;
    width: 100%;
    height: 32px;

    font-weight: 300;
    font-size: 16px;
    line-height: 18px;

`;

const DateBlock = styled.div`
    position: absolute;
    bottom: 10px;
    right: 15px;
    cursor: pointer;
        color: #8F8F8F;

`;
