import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from "../assets/icons/search-icon.svg";
import KitInput from "../ui-kit/Input";
import useOutsideClick from "../hooks/useOutsideClick";
import { useDispatch, useSelector } from "react-redux";
import { getGroupsList } from "../redux/slices/groupSlice";
import { Checkbox } from "antd";
import { getTemplates } from "../redux/slices/templateSlice";
import { useDebounce } from "use-debounce";

const GroupFilterC = ({ group, onSave, list }) => {
    const plainOptions = group.users.map(u =>  u.userId);

    const [indeterminate, setIndeterminate] = useState(list && list.length > 0 ? true : false);
    const [checkAll, setCheckAll] = useState(list && list.length === plainOptions.length ? true : false);
    const [checkedList, setCheckedList] = useState(list || []);


    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };


    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        onSave(checkedList);
    }, [checkedList, onSave]);

    return (
        <GroupFilter>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>{group.name}</Checkbox>
            <GroupUsersFilter>
                <GroupUsersFilterC checkedList={checkedList} setCheckedList={setCheckedList} setCheckAll={setCheckAll} setIndeterminate={setIndeterminate} users={group.users} />
            </GroupUsersFilter>
        </GroupFilter>
    );
};

const GroupUsersFilterC = ({ users, setIndeterminate, setCheckAll, setCheckedList, checkedList }) => {

    const onChange = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < users.length);
        setCheckAll(list.length === users.length);
    };

    return (
        <UsersFilters options={users.map(u => ({ label: `${u.firstName} ${u.lastName || ''}`, value: u.userId }))} value={checkedList} onChange={onChange} />
    );
};


const SearchFilter = ({ handleSearch }) => {
    const dispatch = useDispatch();

    const [isFiltersOpen, setIsFiltersOpen] = useState(false);

    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState();
    const groups = useSelector(getGroupsList);

    const [searchD] = useDebounce(search, 500);


    const onFilterChange = (data) => {
        setFilters(data);
    };

    useEffect(() => {
        dispatch(getTemplates({ name: searchD, filters }));
    }, [searchD, filters, dispatch]);


    const ref = useRef(null);

    useOutsideClick(isFiltersOpen, ref, () => setIsFiltersOpen(false));

    return (
        <Container onClick={() => setIsFiltersOpen(true)} ref={ref}>
            {!isFiltersOpen &&
                <FakeSearch
                    addonBefore={filters && filters.length > 0 && <span>Фильтров: {filters.length}</span>}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    prefix={<SearchIcon />}
                    placeholder=" Поиск по названию, телефону, почте"
                />
            }
            {isFiltersOpen &&
                <FiltersContainer>
                    <Search onChange={(e) => setSearch(e.target.value)} value={search}  prefix={<SearchIcon />} placeholder="  Поиск по названию, телефону, почте, имени" />
                    <Filters>
                        {groups.owner && groups.owner.map((group) => {
                            return group && group.users && group.users.length > 0 && (
                                <GroupFilterC key={group.id} onSave={onFilterChange} list={filters} group={group} />
                            );
                        })}
                    </Filters>
                </FiltersContainer>
            }
        </Container>
    );
};



const Container = styled.div`
    position: relative;
`;

const FakeSearch = styled(KitInput)`
    width: 300px;
    background: #FFFFFF !important;
    padding: 0px;
    
    &:hover,
    &:focus {
        box-shadow: none !important;
        border-right-width: 0 !important;
        outline: 0 !important;
    }
    
    
    
    .ant-input-prefix {
        margin-left: 15px !important;
    }
    
    .ant-input-wrapper {
        height: 100%;
        
        .ant-input-affix-wrapper {
            height: 50px;
            outline: 0;
            border: 1px rgb(0 0 0) !important;
        }
    }
    
    .ant-input-group-addon {
        background: #333333;
        color: white;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
    }
`;

const Search = styled(KitInput)`
    background: #f4f4f4;
    border: none;
    box-shadow: none;
    
    input {
        background: #f4f4f4;
    }
    
    &:hover {
        box-shadow: none !important;
        border-right-width: none !important;
    }
`;

const FiltersContainer = styled.div`
    z-index: 10;
    position: absolute;
    top: -2px;
    left: -450px;
    width: 400px;
    height: auto;
    
    background: #FFFFFF;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    
    @keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
}
    
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
    
`;


const Filters = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    
    .ant-checkbox-inner {
        background: white;
        border-color: #333333;
    }
    
    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #333333;
    }
    
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: #333333;
    }

`;
const GroupFilter = styled.div`
    margin-bottom: 15px;
    
    &:last-child {
        margin-bottom: 0;
    }
`;

const GroupUsersFilter = styled.div`
    margin-left: 30px;
    display: flex;
    flex-direction: column;
`;

const UsersFilters = styled(Checkbox.Group)`
    display: flex;
    flex-direction: column;
`;

export default SearchFilter;
