import ShortCrypt from 'short-crypt';
import siteConfig from "../config";

const sc = new ShortCrypt(siteConfig.templateSecretKey);


export function encryptId(id) {
    return id ? sc.encryptToURLComponent(id.toString()) : undefined;
}

export function decryptId(cipher) {
    const decrypt = sc.decryptURLComponent(cipher);

    const enc = new TextDecoder("utf-8");
    const arr = new Uint8Array(decrypt);
    return enc.decode(arr);
}
