import React from "react";
import { Table } from "antd";
import styled from "styled-components";

const FilesTable = ({ files, visible, rowSelection }) => {

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name)
        },
        {
            title: 'Размер',
            dataIndex: 'size',
            sorter: (a, b) => a.size - b.size,
            render: (v) => formatBytes(v)

        },
        {
            title: 'Дата загрузки',
            dataIndex: 'created',
            render: (text) => {
                return new Date(text).toLocaleDateString();
            },
            sorter: (a, b) => new Date(a.created) - new Date(b.created)
        },
    ];


    return visible && (
        <StyledTable
            rowSelection={rowSelection}
            columns={columns}
            dataSource={files}
        />

    );
};

export default FilesTable;

const StyledTable = styled(Table)`
    width: 100%;
`;

const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) {return '0 Bytes';}

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
