import React from "react";
import styled from "styled-components";

import { Button } from "antd";

const KitButton = (props) => {
    return (
        <StyledButton {...props} />
    );
};


export default KitButton;

const StyledButton = styled(Button)`
    width: 100%;
    height: 50px;
    background: ${props => props.color === 'black' ? '#333333 !important' : (props.color === 'white' ? 'white !important' : '#F4F4F4 !important')};
    border-radius: 10px;
    border: none;
    padding: 15px;
    
    span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: ${props => props.color === 'black' ? '#FFFFFF' : (props.color === 'white' ? 'red !important' : '#000000')};
    }
    
.ant-btn:active,
.ant-btn:hover {
  background: #E3E3E3 !important;
}


`;
