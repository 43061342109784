import { privateAxios } from "../api/axios";

const uploadFile = async (data) => {


     return await privateAxios.post(`file`, data).then((response) => {
        return response.data;
    })
        .catch(error => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        });
};

const listFiles = async () => {
    const response = await privateAxios.get(`file/list`);

    return response.data;
};

const lookup = async ({ query = '', type }) => {
    const response = await privateAxios.get(`file/lookup?query=${query}&type=${type}`);

    return response.data;
};

const deleteById = async (id) => {
    const response = await privateAxios.delete(`file/${id}`);

    return response.data;
};



export default {
    uploadFile,
    listFiles,
    lookup,
    deleteById
};
