import React, { useEffect } from 'react';
import styled from "styled-components";
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import Notification from "./Notification";
import { useDispatch, useSelector } from "react-redux";
import { answerToInvitation, getGroups, getReceivedInvitations } from "../redux/slices/groupSlice";
import OfferNotification from "./OfferNotification";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import { getNotifications } from "../redux/slices/templateSlice";
// eslint-disable-next-line

const Notifications = ({ onClose }) => {
    const dispatch = useDispatch();
    const { receivedInvitations } = useSelector((state) => state.group);
    const { notifications, total } = useSelector((state) => state.template);

    const handleAnswerToGroup = async (id, answer) => {
        await dispatch(answerToInvitation({
            groupInvitationId: id,
            status: answer
        }));

        dispatch(getReceivedInvitations());
        dispatch(getGroups());
    };


    useEffect(() => {
        dispatch(getReceivedInvitations());
        dispatch(getNotifications());
        // eslint-disable-next-line
    }, []);

    const filteredReceivedInvitations = receivedInvitations.filter(i => i.status === 'sent');

    return <Container>
        <Header>
            <Title>Лента уведомлений</Title>
            <CloseIcon onClick={onClose}/>
        </Header>
        <Body id="scrollableDiv">
            {filteredReceivedInvitations && filteredReceivedInvitations.length > 0 &&
                filteredReceivedInvitations.map((notification, i) =>
                    <Notification key={i + 'group'}
                                  notification={notification}
                                  handleAnswer={handleAnswerToGroup}
                    />
                )
            }
            <InfiniteScroll
                dataLength={notifications.length}
                next={() => dispatch(getNotifications())}
                hasMore={total ? notifications.length < total : false}
                loader={
                <div style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin style={{ textAlign: 'center' }} />
                </div>
            }
                scrollableTarget="scrollableDiv"
            >
                {notifications && notifications.length > 0 &&
                    notifications.map((notification, i) => <OfferNotification key={i + "offer"} notification={notification} />
                    )
                }

            </InfiniteScroll>
            {(!filteredReceivedInvitations || filteredReceivedInvitations.length === 0) && (!notifications || notifications.length === 0) && <ComingSoon>Уведомлений нет</ComingSoon>}
        </Body>
    </Container>;
};

export default Notifications;

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Header = styled.div`
    svg {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
    }
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
    text-align: center;
    margin: 53px 45px 33px 25px;
`;

const Body = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    height: 85%;
`;

const ComingSoon = styled.div`
    text-align: center;
`;
