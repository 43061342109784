import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Spin } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
    currentTemplate,
    deleteFile, getTemplateById,
    refreshTemplate,
    updateTemplate, updateTemplateApi,
    uploadFile
} from "../../redux/slices/templateSlice";
import FileChanger from "../../ui-kit/FileChanger";
import FilesTable from "../Files/components/FilesTable";
import { getFiles, getFilesList, getSelectedFile, setFile } from "../../redux/slices/fileSlice";
import SendTemplateModal from "./components/SendTemplateModal";
import KitInput from "../../ui-kit/Input";
import KitButton from "../../ui-kit/Button";
import { decryptId } from "../../helpers/IdEncrypt";
import { makeViewerUrl } from "../../helpers/subdomainViewerUrl";
import FileAndLinkUploadComponent from "../Files/components/FileUploadComponent";
import OnboardingModalWide from "../OnboardingModalWide";
import {updateUser} from "../../redux/slices/userSlice";


const UpdateTemplate = (props) => {
    const search = useLocation().search;
    const isSend = new URLSearchParams(search).get('send');

    const templateState = useSelector((state) => state.template);
    const { user } = useSelector((state) => state.user);

    const files = useSelector(getFilesList);
    const selectedFile = useSelector(getSelectedFile);

    const template = useSelector(currentTemplate);

    const loadingStatus = useSelector(state => state.template.status);

    const dispatch = useDispatch();

    const [isSendModalOpen, setIsSendModalOpen] = useState(isSend);
    const [isOpenOnboardingModal, setIsOpenOnboardingModal] = useState(false)
    const [onboardingStep, setOnboardingStep] = useState(user?.onboardingStep)

    let { templateId } = useParams();
    templateId = decryptId(templateId);

    const [fileSource, setFileSource] = useState(template.file ? 'file' : 'download');
    const [isError, setIsError] = useState(false);
    const isHighlightButton = !!template.file || !!template.linkUrl;

    useEffect(() => {
        if (!user?.isOnboardingFinished && ['UPLOAD_FILE', 'SEND_OFFER', 'FINAL'].includes(user?.onboardingStep) ) {
            setIsOpenOnboardingModal(true)
        }
    }, [user])

    useEffect(() => {
        if (!template.id) {
            dispatch(getTemplateById(templateId));
        }
        dispatch(getFiles());

    }, [template.id, templateId, dispatch]);

    useEffect( () => () => dispatch(refreshTemplate()), [dispatch]);

    const handleUpdate = (data) => {
        dispatch(updateTemplate(data));
        setNextOnboardingStep()
    };

    const setNextOnboardingStep = () => {
        if (!user?.isOnboardingFinished && user.onboardingStep === 'UPLOAD_FILE') {
            dispatch(updateUser({
                onboardingStep: 'SEND_OFFER'
            }))
        }

        setOnboardingStep('SEND_OFFER')
        setIsOpenOnboardingModal(true)
    }

    const handleFile = async (data) => {
        let type;
        if (data.mimetype.includes("image")) {
            type = 'IMAGE'
        } else if (data.mimetype.includes('application/pdf')) {
            type = 'PDF'
        } else {
            type = 'DOCUMENT'
        }

        const response = await dispatch(uploadFile(data));

        dispatch(updateTemplate({
            type
        }));

        setNextOnboardingStep()

        return response;
    };

    const handleDeleteFile = id => {
        dispatch(deleteFile(id));
    };

    const handleSelectFileInTable = (event) => {
        dispatch(setFile(event));
    };

    const selectFile = () => {
        let type;
        if (selectedFile.mimetype.includes("image")) {
            type = 'IMAGE'
        } else if (selectedFile.mimetype.includes('application/pdf')) {
            type = 'PDF'
        } else {
            type = 'DOCUMENT'
        }

        dispatch(updateTemplate({
            file: selectedFile,
            type
        }));
    };

    const onSubmit = () => {
        dispatch(updateTemplateApi());
    };

    const openTemplateLink = () => {
        dispatch(updateTemplateApi()).then(() => {
            window.open(makeViewerUrl(user.email, templateId, template.type, 'view'), '_blank', 'noopener,noreferrer');
        });
    };

    const sendButton  = () => {
        dispatch(updateTemplateApi()).then(() => {
            setIsSendModalOpen(true);
        });
    };

    const closeSendModal = ({ isOnboarding = false }) => {
        setIsSendModalOpen(false);

        if (isOnboarding) {
            dispatch(updateUser({
                onboardingStep: 'FINAL'
            }))

            setOnboardingStep('FINAL')

            setIsOpenOnboardingModal(true)
        }
    }

    return (
        <CreateTemplateContainer loading={loadingStatus === 'loading'} >
            {loadingStatus === 'loading' ? <Spin size="large"/> :
                <>
                    <Header>
                        <StyledLink to="/templates">
                            <ArrowLeftOutlined style={{ color: 'black' }}/>
                            <p>
                                Все офферы
                            </p>
                        </StyledLink>
                        <div>
                            <StyledButton disabled={isError || !templateState.isChanged} onClick={onSubmit} type="primary" shape="round"
                                    size='large'>
                                {templateState.isChanged ? 'Cохранить' : 'Сохранено'}
                            </StyledButton>
                            <StyledButton disabled={isError || (!template.file && !template.linkUrl)} onClick={openTemplateLink} type="primary" shape="round" size='large'>
                                Просмотреть
                            </StyledButton>
                            <StyledButton disabled={isError || (!template.file && !template.linkUrl)} className={isHighlightButton && "send-button"} onClick={sendButton} type="primary" shape="round" size='large'>
                                Отправить
                            </StyledButton>
                        </div>
                    </Header>

                    <NameBlock>
                        <KitInput onChange={(e) => handleUpdate({ name: e.target.value })} placeholder="Название шаблона"
                                  value={template.name}/>
                        {/*<SourceChanger onChange={(type) => handleUpdate({ type })} source={template.type} />*/}
                    </NameBlock>

                    <Body>
                        <Content>
                            {!template.linkUrl && <FileChanger visible={!template.file} onChange={(fileSource) => setFileSource(fileSource)}
                                         source={fileSource}/>}

                            <FileBlock>
                                {
                                    (
                                        template.linkUrl || template.file || fileSource === 'download' ?
                                            <FileAndLinkUploadComponent
                                                                 handleUpdate={handleUpdate}
                                                                 onSave={handleFile}
                                                                 onDelete={handleDeleteFile}
                                                                 template={template}
                                                                 setLinkIsError={setIsError}
                                            />
                                            :
                                            <FileContainer>
                                                <FilesTable visible={true} files={files} rowSelection={{
                                                    type: 'radio',
                                                    onSelect: handleSelectFileInTable
                                                }}/>
                                                <KitButton style={{ "width": "155px", "height": "50px" }} color="black"
                                                           onClick={selectFile}>Выбрать</KitButton>
                                            </FileContainer>
                                    )
                                }
                            </FileBlock>
                        </Content>
                    </Body>
                </>
            }
            { isSendModalOpen && user &&
                <SendTemplateModal
                    template={templateState.current}
                    visible={isSendModalOpen}
                    onClose={closeSendModal} />
            }
            { isOpenOnboardingModal && user &&
                <OnboardingModalWide
                    visible={isOpenOnboardingModal}
                    user={user}
                    onboardingType={onboardingStep || user?.onboardingStep}
                    onClose={() => setIsOpenOnboardingModal(false)}
                />}
        </CreateTemplateContainer>
    );
};

export default UpdateTemplate;

const FileContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: end;
   height: 100%;
   
   .ant-table-wrapper {
      height: 100% !important;
   }
`;

const Body = styled.div`
    position: relative;
    background: #FFFFFF;
    border-radius: 25px;
    min-height: 500px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 20px 30px;
`;


export const CreateTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.loading ? 'center' : 'none'};
  padding: 0 55px;
  background: #F4F4F4;
  
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    
    div {
        button {
            background: transparent;
            border: none;
            color: #8F8F8F;
            box-shadow: none;
            border-radius: 10px !important;
        }
        
        .send-button {
            background: #44B74C;
            color: white;
            
            &:hover {
                border-radius: 10px;
                background: #333333;
            }
        }
    }
`;



const FileBlock = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 20px;
`;

const NameBlock = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    
    
    >:first-child {
        font-weight: 500;
        font-size: 26px;
        line-height: 132.9%;
        width: 100%;
        border-radius: 15px;
        
        &:hover,
        &:visited,
        &:focus {
            border: none !important;
            box-shadow: none !important;
        }
    }
`;

const StyledButton = styled(Button)`
    margin-left: 5px;
    border-radius: 10px;
    
    &:hover {
        border-radius: 10px;
        background: #333333;
    }
`;

const StyledLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    svg {
        margin-right: 15px;
        width: 15px;
        height: 15px;
    }

    
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #000000;
        margin: 0;
        margin-left: 7px;
    }
`;
