import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row } from "antd";
import { useLocation } from "react-router-dom";
import DynamicIcon from "../../ui-kit/Icon";

import { ReactComponent as SubscribeIcon } from '../../assets/icons/subscribe.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as DeleteHoverIcon } from "../../assets/icons/delete-hover.svg";
import { ReactComponent as DeleteActiveIcon } from "../../assets/icons/delete-active.svg";
import userService from "../../services/user.service";
import config from "../../config";

const SubscribeSettings = ({ user, afterUpdate }) => {
    const search = useLocation().search;
    const isOpenParam = new URLSearchParams(search).get('isOpenParam');

    const [isOpen, setIsOpen] = useState(!!isOpenParam);

    const activateTelegram = () => {
        window.open(`https://t.me/${config.botName}?start=${user.userId}`);
    };

    const deactivateTelegram = async () => {
        await userService.deactivateTelegram();
        afterUpdate();
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (isOpen) {
                afterUpdate();
            }
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line
    }, [afterUpdate]);

    return (
        <Container isOpen={isOpen} onClick={() => !isOpen && setIsOpen(!isOpen)}>
            <Header >
                <Row align="middle">
                    <SubscribeIcon />
                    <p>Уведомления</p>
                </Row>
                <StyledArrowIcon onClick={() => setIsOpen(!isOpen)} className={isOpen ? 'opened' : ''} />
            </Header>
            <Content className={isOpen ? 'opened' : ''}>
                <Body>
                    <p>Подключите уведомления о смене стусов и задачах</p>
                    <TelegramContainer>
                        <p>Telegram</p>
                        {user.telegramId ? <p style={{ "color": "#1AB800" }}>Активно</p> : <p style={{ "cursor": "pointer" }} onClick={activateTelegram}>Активировать</p>}
                        {user.telegramId &&
                            <IconContainer>
                                <DynamicIcon Initial={DeleteHoverIcon} Hover={DeleteActiveIcon} Active={DeleteActiveIcon}  onClick={deactivateTelegram} />
                            </IconContainer>
                        }
                    </TelegramContainer>
                </Body>
            </Content>
        </Container>
    );
};

export default SubscribeSettings;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }

    svg {
        margin-right: 15px;
    }
`;

const Container = styled.div`
    background: #FFFFFF;
    border-radius: 25px;
    padding: 40px;
    cursor: ${props => props.isOpen ? 'inherit' : 'pointer'};
`;

const Content = styled.div`
    margin-left: 30px;
    display: none;
    opacity: 0;
    transition: opacity 600ms, visibility 600ms;

    &.opened {
        display: block;
        opacity: 1;
    }
`;

const Body = styled.div`
    margin-top: 20px;
    margin-left: 20px;
`;

const TelegramContainer = styled.div`
    position: relative;
    margin-top: 30px;
    display: flex;
    align-items: baseline;
    
    p:first-child {
        font-size: 16px;
        font-weight: 600;
    }

    p:nth-child(2) {
        margin-left: 30px !important;;
        font-weight: 300;
        font-size: 16px;
        text-decoration-line: underline;
    }
`;

const StyledArrowIcon = styled(ArrowIcon)`
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease;
    transform: rotate(0deg);
    cursor: pointer;

    &.opened {
        transform: rotate(180deg);
    }
`;

const IconContainer = styled.div`
    position: absolute;
    left: 180px;
    cursor: pointer;
    top: 2px;
`;
