import React, { useEffect, useRef, useState } from "react";
import Modal from "../ui-kit/Modal";
import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import KitInput from "../ui-kit/Input";
import KitButton from "../ui-kit/Button";
import { ReactComponent as AvatarUploadIcon } from "../assets/icons/avatar-upload.svg";

import { Form, notification } from "antd";
import { FileDrop } from "react-file-drop";
import { UploadInput } from "./Files/components/FileUploadComponent";
import { updateUser } from "../redux/slices/userSlice";
import userService from "../services/user.service";
import { logout } from "../redux/actions/auth";
import Notification from "./Notification";
import { answerToInvitation, getReceivedInvitations } from "../redux/slices/groupSlice";

const PersonalInfoModal = ({ visible, onClose, isOnRegistration, openNextOnboardingModal }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { receivedInvitations } = useSelector((state) => state.group);

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [companyName, setCompanyName] = useState();
    const [file, setFile] = useState();
    const [avatarUrl, setAvatarUrl] = useState();
    const [isDragging, setIsDragging] = useState(false);
    const [isLoading, setIsLoading] = useState();

    const fileInputRef = useRef(null);

    const onTargetClick = () => {
        fileInputRef.current.click();
    };

    const onDrop =  (files) => {
        setFile(files[0]);
    };

    const onFileClick = (event) => {
        setFile(event.target.files[0]);
    };

    const onInputClick = (event) => {
        event.target.value = '';
    };

    const handleAnswer = async (id, answer) => {
        await dispatch(answerToInvitation({
            groupInvitationId: id,
            status: answer
        }));

        notification.close(id);

        await dispatch(getReceivedInvitations());
    };


    const onSubmit = async () => {
       setIsLoading(true);

        // If it's after registration we will show notifications
        if (isOnRegistration) {
            const filteredReceivedInvitations = receivedInvitations.filter(i => i.status === 'sent');

            if (filteredReceivedInvitations && filteredReceivedInvitations.length > 0) {

                filteredReceivedInvitations.map((n) => {
                    const key = n.id;
                    return notification.open({
                        message: `Notification`,
                        description: <Notification notification={n} handleAnswer={handleAnswer}/>,
                        placement: 'bottomLeft',
                        duration: 5,
                        key,
                    });
                });
            }

        }

        const currentStep = user.onboardingStep

        await Promise.all([
            dispatch(updateUser({
                firstName,
                lastName,
                companyName,
                onboardingStep: 'INITIAL'
            })),
            userService.uploadAvatar(file)
        ]);

        setIsLoading(false);

        onClose();

        if (!user.isOnboardingFinished && currentStep === 'SET_NAME') {
            openNextOnboardingModal()
        }
    };

    const handleLogout = () => {
        dispatch(logout());
        window.location.reload();
    };


    useEffect(() => {
        setFirstName(user?.firstName);
        setLastName(user?.lastName);
        setCompanyName(user?.companyName);

        setAvatarUrl(user?.avatar?.url);
    }, [user]);

    return (
        <StyledModal
            className="personalInfoModal"
            title={!user?.isOnboardingFinished ? "Заполните аккаунт" : "Ваш аккаунт"}
            maskClosable={!!user.isOnboardingFinished}
            closable={!!user.isOnboardingFinished}
            visible={visible}
            onCancel={onClose}
            footer={null}

        >
            <CreateModalContainer onFinish={onSubmit}>
                <Description>Заполните поля, чтобы продолжить.</Description>
                <AvatarUploadContainer>
                    <StyledFileDrop
                        isDragging={isDragging}
                        onDrop={onDrop}
                        onDragOver={(e) => {setIsDragging(true);}}
                        onDragLeave={(e) => { setIsDragging(false);}}
                        onTargetClick={onTargetClick}
                    >
                        <UploadInput
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            name="document"
                            onChange={onFileClick}
                            onClick={onInputClick}
                            data-cy="uploadFile"
                        />
                        {file || avatarUrl?
                            <>
                                <PreviewAvatar src={file ? URL.createObjectURL(file) : avatarUrl} />
                            </> :
                            <>
                                <AvatarUploadIcon />
                                <p>Добавить<br/>фото</p>
                            </>
                        }

                    </StyledFileDrop>
                </AvatarUploadContainer>
                <StyledInput placeholder="Имя" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                <StyledInput placeholder="Фамилия (необязательно)" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                <StyledInput placeholder="Компания (необязательно)" onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
                <StyledButton disabled={!firstName} htmlType="submit" color="black">
                    {isLoading ? 'Загрузка...' : 'Сохранить'}
                </StyledButton>
                {user.onboardingStep !== 'SET_NAME' &&
                <StyledButton style={{ "boxShadow": "none" }} onClick={handleLogout} color="white">
                    Выйти из аккаунта
                </StyledButton>}
            </CreateModalContainer>

        </StyledModal>
    );
};

export default PersonalInfoModal;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        height: 650px;
        width: 400px;
    }
    
    .ant-modal-header {
        padding: 41px 30px 4px !important;
    }
    
    .ant-modal-body {
        padding: 6px 30px 15px !important;
    }
`;

const StyledInput = styled(KitInput)`
    height: 55px;
    margin-top: 10px;
`;

const StyledButton = styled(KitButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 55px;
`;

const Description = styled.div`
    font-weight: 300;
    font-size: 16px;
    text-align: center;    
    color: #000000;
    margin-bottom: 10px;
`;

const CreateModalContainer = styled(Form)`
`;

const AvatarUploadContainer = styled.div`
    margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    
    svg {
        cursor: pointer;
    }
    
    p {
        position: absolute;
        bottom: 20px;
        left: 137px;
        color: white;
        text-align: center;
    }
`;

const StyledFileDrop = styled(FileDrop)`
    height: 140px;
`;

const PreviewAvatar = styled.img`
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: 2px solid #3D61FF;
    cursor: pointer;
    object-fit: cover
`;

