import React, { useState } from "react";
import Modal from "../../../ui-kit/Modal";
import styled from 'styled-components';
import { useDispatch } from "react-redux";
import {
    createTemplate,
} from "../../../redux/slices/templateSlice";
import KitInput from "../../../ui-kit/Input";
import KitButton from "../../../ui-kit/Button";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/narrow.svg";
import { Form } from "antd";

const CreateTemplateModal = ({ visible, isLoading, onClose, onSave }) => {
    const [templateName, setTemplateName] = useState('Оффер1');

    const dispatch = useDispatch();

    const onSubmit = () => {
        dispatch(createTemplate({
            name: templateName
        }));
    };

    return (
        <StyledModal
            title="Придумайте название"
            maskClosable={true}
            destroyOnClose
            visible={visible}
            onCancel={onClose}
            footer={null}

        >
            <CreateModalContainer onFinish={onSubmit}>
                <Description>Это название будет видеть получатель</Description>
                <StyledInput onChange={(e) => setTemplateName(e.target.value)} value={templateName} />
                <StyledButton htmlType="submit" color="black">
                    Дальше
                    <ArrowIcon />
                </StyledButton>
            </CreateModalContainer>

        </StyledModal>
    );
};

export default CreateTemplateModal;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        height: 300px;
        width: 400px;
    }
    
    .ant-modal-header {
        padding: 41px 30px 4px !important;
    }
    
    .ant-modal-body {
        padding: 6px 30px 15px !important;
    }
`;

const StyledInput = styled(KitInput)`
    height: 55px;
`;

const StyledButton = styled(KitButton)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 20px;
    height: 55px;

    span { 
        font-size: 18px !important;
        font-weight: 500;
    }
    
    svg {
        color: white !important;
        width: 30px;
        height: 30px;
    }
`;

const Description = styled.div`
    font-weight: 300;
    font-size: 16px;
    text-align: center;    
    color: #000000;
    margin-bottom: 20px;
`;

const CreateModalContainer = styled(Form)`
`;
