import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import styled from "styled-components";

import { Form, Spin } from "antd";
import { ReactComponent as ArrowIcon } from "../../assets/icons/narrow.svg";
import Input from "../../ui-kit/Input";
import KitButton from "../../ui-kit/Button";
import authService from "../../services/auth.service";

const NewPassword = () => {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangeConfirmPassword = (e) => {
        const confirmPassword = e.target.value;
        setConfirmPassword(confirmPassword);

        if (password !== confirmPassword) {
            setError('Пароли не совпадают');
        } else {
            setError('');
        }
    };

    const handleNewPassword = (e) => {
        setLoading(true);

        authService.newPassword({ password, token })
            .then((data) => {
                setIsSuccess(true);
            })
            .catch((e) => {
                setError(e);
                setLoading(false);
                setIsSuccess(false);
            });

    };


    return (
        <NewPasswordContainer isSuccess={isSuccess} onFinish={handleNewPassword} >
            <Title>
                {!isSuccess ? 'Новый пароль' : 'Вы успешно изменили пароль'}
            </Title>
            {!isSuccess ?
                <>
                    <PasswordInput
                        type="password"
                        placeholder="Пароль"
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                        isError={error}
                    />

                    <PasswordInput
                        type="password"
                        placeholder="Подтверждение пароля"
                        name="password"
                        value={confirmPassword}
                        onChange={onChangeConfirmPassword}
                        isError={error}
                    />

                    <StyledButton
                        disabled={ !password || !confirmPassword || error }
                        color="black"
                        htmlType="submit"
                    >
                        Изменить пароль
                        {loading ? <Spin style={{ "margin-top": "5px" }} /> : <ArrowIcon />}
                    </StyledButton>

                    <Message>
                        {error ? <Error>{error}</Error> : ''}
                    </Message>
                </>
                :
                <StyledLink to="/auth/login">
                    <StyledButton color="black">
                        Войти
                    </StyledButton>
                </StyledLink>
            }

        </NewPasswordContainer>
    );
};

export default NewPassword;


const NewPasswordContainer = styled(Form)`
  width: 400px;
  height: ${props => props.isSuccess ? '200px' : '340px;'};
  background: #FFFFFF;
  border-radius: 25px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  
  margin-top: 37px;
  margin-bottom: 35px;
`;

const PasswordInput = styled(Input)`
    background: ${props => props.isHighlighted ? '#F4F4F4' : '#F4F4F4'};
    border-radius: 10px;   
    border: ${props => props.isError ? '1px solid red !important' : '1px solid #f1f1f1 !important'};
    
    width: 340px;
    height: 55px;
    padding-left: 20px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
      font-weight: 300;
      color: #000000;
    }

    &:focus {
      outline-color: ${props => props.isError ? 'red' : '#000000;'};
      outline: 1;
    }
    
    margin-top: 10px;
   
`;

const StyledButton = styled(KitButton)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    width: 340px;

    span {
        font-weight: 600;
        line-height: 21px;
        font-size: 18px !important;
        padding-left: 5px;
    }
    
    svg {
        color: white !important;
        width: 30px;
        height: 30px;
    }
`;

const Message = styled.div`
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
    text-align: center;
    margin-top: 12px;
`;

const Error = styled.span`
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;

    text-align: center;
    color: red;
`;

const StyledLink = styled(Link)`
  color: #3D61FF;
`;
