import React from 'react';
import ANTDModal from 'antd/es/modal';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../assets/icons/closeicon.svg';

import 'antd/es/modal/style/css';



const Modal = ({ destroyOnClose = true, ...props }) => (
  <StyledModal destroyOnClose={destroyOnClose} {...props}  closeIcon={<CloseIcon/>} />
);

export default Modal;

const StyledModal = styled(ANTDModal)`

.ant-modal-header {
    padding: 41px 48px 4px !important;
    border-bottom: unset !important;
    

    .ant-modal-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
        text-align: center;
        padding-top: 15px;
    }
}

.ant-modal-body {
    padding: 6px 48px 15px !important;
}

.ant-modal-footer {
    padding: 0px 48px 30px 33px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    text-align: unset !important;
    border-top: unset !important;
}

.ant-modal-content {
    background: #FFFFFF;
    border-radius: 25px;
    overflow: hidden;
    
    .ant-modal-close {
        padding: 10px 5px !important;
    }
}

`;


